import React, { useEffect, useMemo } from "react";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Select from "react-select";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Avatar_02 from "../../../assets/img/profiles/smile-icon.svg";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
// import React, { Component, useState } from 'react';
import { render } from "react-dom";
import Map from "./GeoMap";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Delete from "../../../modelbox/Delete";
// import Header from "../../../pages/Sidebar/header";
// import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
//import Select from "react-select";
import { read, utils } from "xlsx";
import axios from "axios";
import Swal from "sweetalert2";
// import Select from "@atlaskit/select";
import moment from "moment";
import { FiUpload } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import {
  Typography,
  Grid,
  Stack,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  useMediaQuery,
  Toolbar,
} from "@mui/material";
import GeoMap from "./GeoMap";
import { getElRoot } from "@fullcalendar/react";

const GeoFencingReg = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openmodal, setOpenmodal] = useState(true);
  const [openloc, setOpenLoc] = useState(false);
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [getloc, setGetLoc] = useState([]);
  const [loc, setLoc] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [temp, setTemp] = useState("per");
  const [rad, setRad] = useState(0);
  const [dept, setDept] = useState("");
  const [getEmpl, setGetEmpl] = useState([]);
  const [getApp, setGetApp] = useState([]);
  const [allcheck, setAllCheck] = useState(false);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [locValue, setLocValue] = useState({ name: "", lat: 0, long: 0 });
  const token = sessionStorage.getItem("access_token");
  const [center, setCenter] = useState({ lat: 19.11578, lng: 72.847511 });
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event, value, index) => {
    // setEditTracking(value);
    setDeleteId(index);
    setAnchorEl(event.currentTarget);
  };

  const handleModalOpen = () => {
    // setOpenmodal(true);
    // setErrorMsg("");
    // setIsSuccess(false);
    setOpenLoc(true);
  };
  const handleModalClose = () => {
    // setOpenmodal(false);
    setOpenLoc(false);

    // setErrorMsg("");
    // setIsSuccess(false);
    // setSelectedFile({});
  };
  const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // height: "500px",
    // transform: "translate(-50%, -50%)",
    // textAlign: "center",
    // width: "50%",
    // minWidth: 250,
    // bgcolor: "background.paper",
    // boxShadow: 24,
    // p: 4,
    // overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "350px",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "37%",
    minWidth: 200,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "13px",
    p: 2,
  };

  const [radius1, setRadius1] = useState([
    {
      id: 1,
      name: "604-01, B Wing, HDIL Kaledonia, Sahar Rd, Andheri East, Mumbai, Maharashtra 400069",
      latitude: "19.115780",
      longitude: "72.847511",
      circle: {
        radius: 1000,
        options: {
          strokeColor: "#ff0000",
        },
      },
    },
  ]);

  const handleAddlocation = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/company_locations/`,
        {
          coregid: sessionStorage.getItem("coid"),
          name: locValue.name,
          lat: locValue.lat,
          long: locValue.long,
        },
        {
          // params: {
          //   coregid:sessionStorage.getItem("coid")
          // },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.data;

      // console.log(data)
      GetLocation();
      handleModalClose();
      alert("Location Added Successfully");

      // setGetEmpl(res.data.map((ele)=>({label:ele.name, value:ele.empid})))
    } catch (err) {
      if (err?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          text: `${err?.response?.data?.error}!`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong",
        });
      }
    }
  };

  const getFencingData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/geo_location_permissions/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
          toggle: "p",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //setData(res.data);
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            joining_date: ele.joining_date
              .substr(0, 10)
              .split("-")
              .reverse()
              .join("-"),
          }))
        );
        setGetEmpl(
          res.data.map((ele, index) => ({
            label: ele.name,
            value: ele.id,
            department: ele.department,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFencingData();
  }, []);

  const GetLocation = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/company_locations/`,
        {
          params: {
            coregid: sessionStorage.getItem("coid"),
          },
        }
      );
      const data = await res.data;

      console.log(data);

      setGetLoc(
        res.data.map((ele) => ({ ...ele, label: ele.name, value: ele.id }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const HandleLocSub = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/geo_location_permissions/`,
        {
          coregid: sessionStorage.getItem("coid"),
          name: loc.name,
          lat: loc.lat,
          long: loc.long,
          empid: getApp.map((ele) => ele.value),
          temp: temp === "per" ? false : true,
          from_date: fromDate,
          to_date: toDate,
          radius: rad / 1000,
        }
      );
      // const data = await res.data;

      // console.log(data)

      // setGetEmpl(res.data)
      Swal.fire({
        icon: "success",
        // title: "Oops...",
        text: "Geo Fencing added Successfully",
      });
      setOpenmodal(!openmodal);
    } catch (err) {
      if (err?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          text: `${err?.response?.data?.error}!`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong",
        });
      }
    }
  };
  const getLatLong = async (address) => {
    const apiKey = "AIzaSyC-BtiuD2tU8x4DsHhWhsfhrWQDem2Ewz4";
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: address,
          key: apiKey,
        },
      }
    );

    const { lat, lng } = response.data.results[0].geometry.location;
    console.log(`Latitude: ${lat}, Longitude: ${lng}`);
  };

  useEffect(() => {
    getLatLong(locValue);
  }, [locValue]);

  const filteredData = useMemo(() => {
    return data
      .filter((x) => x.name.toLowerCase().includes(name.toLowerCase()))
      .filter((x) =>
        x.department.toLowerCase().includes(department.toLowerCase())
      )
      .filter((a) =>
        a.designation.toLowerCase().includes(designation.toLowerCase())
      );
  }, [name, department, designation, data]);

  const EmployeeData = useMemo(() => {
    if (dept?.value === undefined) return getEmpl;
    return getEmpl.filter((x) =>
      x.department?.toLowerCase().includes(dept?.value?.toLowerCase())
    );
  }, [getEmpl, dept]);

  const handleDeleteFencing = () => {
    handleClose();
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/geo_location_permissions/`,
        {
          data: { id: [deleteId] },
        }
      )
      .then((res) => {
        getFencingData();
        setDeleteId(null);
        Swal.fire({
          icon: "success",
          text: "Geo Fencing Disabled Successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  useEffect(() => {
    GetLocation();
  }, []);

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "id",
      sorter: (a, b) => a.emp_id.localeCompare(b.emp_id),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
    },
    {
      title: "Joining Date",
      dataIndex: "joining_date",
      sorter: {
        compare: (a, b) =>
          moment(a.joining_date, "DD-MM-YYYY") -
          moment(b.joining_date, "DD-MM_YYYY"),
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === true
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text === true ? "Assigned" : "Not Assigned"}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <IconButton
            aria-label="more"
            onClick={(e) => handleClick1(e, text, text.id)}
            aria-haspopup="true"
            aria-controls="long-menu"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            onClose={handleClose}
            open={open}
          >
            {/* <MenuItem
              //   onClick={() => handleViewPorject("view")}
              data-bs-toggle="modal"
              data-bs-target="#edit_project"
            >
              View Tracking
            </MenuItem> */}
            {/* <MenuItem
              onClick={() => handleClose()}
              data-bs-toggle="modal"
              data-bs-target="#edit_tracking"
            >
              {editTracking.status === "Applicable" ? "Edit" : "Add"}
            </MenuItem> */}
            <MenuItem
              onClick={() => handleDeleteFencing()}
              // data-bs-toggle="modal"
              // data-bs-target="#edit_project"
            >
              Disable Geo Fencing
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const editRadius = (e) => {
    let data = [...radius1];
    if (loc) {
      setCenter({ lat: loc.lat, lng: loc.long });
      setRadius1([
        {
          id: loc.value,
          name: loc.name,
          latitude: loc.lat,
          longitude: loc.long,
          circle: {
            radius: rad,
            options: {
              strokeColor: "#ff0000",
            },
          },
        },
      ]);
    } else {
      setCenter({ lat: 19.11578, lng: 72.847511 });
      setRadius1([
        {
          id: 1,
          name: "604-01, B Wing, HDIL Kaledonia, Sahar Rd, Andheri East, Mumbai, Maharashtra 400069",
          latitude: "19.115780",
          longitude: "72.847511",
          circle: {
            radius: rad,
            options: {
              strokeColor: "#ff0000",
            },
          },
        },
      ]);
    }
  };

  useEffect(() => {
    editRadius();
  }, [loc, rad]);
  console.log(getApp);

  const SelectAll = () => {
    setGetApp(...getEmpl);
  };

  useEffect(() => {
    SelectAll();
  }, [allcheck]);

  console.log(getApp);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />

      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="page-title">Geo Fencing</h3>
                <button
                  style={{
                    padding: "7px 15px",
                    backgroundColor: "#3298DB",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onClick={() => setOpenmodal(!openmodal)}
                >
                  {openmodal === true ? " Add Geo Fencing" : "Back"}
                </button>
              </div>
            </div>
          </div>
          {openmodal === true ? (
            <div>
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Employee Name"
                      options={[...new Set(data?.map((item) => item.name))].map(
                        (ele) => ({
                          label: ele,
                          value: ele,
                        })
                      )}
                      value={
                        name !== ""
                          ? {
                              label: name,
                              value: name,
                            }
                          : null
                      }
                      onChange={(e) => setName(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Department"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.department)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        department !== ""
                          ? {
                              label: department,
                              value: department,
                            }
                          : null
                      }
                      //isClearable={true}
                      isSearchable
                      onChange={(e) => {
                        setDepartment(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Designation"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.designation)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        designation !== ""
                          ? {
                              label: designation,
                              value: designation,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setDesignation(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <a
                    href="#"
                    className="btn-block"
                    style={{
                      //fontSize: "14px",
                      height: "38px",
                      background: "#3298DB",
                      //color:"white"
                    }}
                    onClick={() => (
                      setName(""), setDepartment(""), setDesignation("")
                    )}
                  >
                    <img
                      tabindex="0"
                      data-bs-toggle="tooltip"
                      title="clear all filter"
                      style={{
                        height: "38px",
                        backgroundColor: "#3298DB",
                        padding: "7px 5px",
                        borderRadius: "5px",
                      }}
                      src={FilterOff}
                    />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginRight: "10px",
                        justifyContent: "end",
                      }}
                    ></div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          pagination={{
                            total: filteredData.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={filteredData}
                          // rowKey={(record) => record.id}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                height: "556px",
              }}
            >
              <div
                style={{
                  border: "1px solid white",
                  width: "50%",
                  height: "100%",
                  backgroundColor: "white",
                  padding: "20px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>Set Base Location and Geo Fencing Range</h4>
                  <button
                    style={{
                      padding: "5px 15px",
                      backgroundColor: "#3298DB",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleModalOpen()}
                  >
                    Add Location
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "25px",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <label>Department:</label>
                    <Select
                      options={[
                        ...new Set(data?.map((item) => item.department)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={dept}
                      onChange={(e) => setDept(e)}
                    />
                  </div>
                  <div style={{ width: "35%" }}>
                    <label>Applicable To :</label>
                    <Select
                      isMulti
                      options={EmployeeData}
                      onChange={(e) => setGetApp(e)}
                    />
                    <span
                      style={{
                        marginTop: "18px",
                        // fontSize: "14px",
                        // fontWeight: "700",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => setAllCheck(!allcheck)}
                        checked={allcheck}
                      />
                      &nbsp;&nbsp;&nbsp;<label>All Employee</label>
                    </span>
                  </div>

                  <div style={{ width: "25%" }}>
                    <label>Select location:</label>
                    <Select
                      options={getloc}
                      value={loc}
                      onChange={(e) => setLoc(e)}
                    />
                  </div>
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    float: "left",
                  }}
                >
                  <label>Applicable Type :</label>&nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="per"
                    onChange={() => setTemp("per")}
                    checked={temp === "per" ? true : false}
                  />{" "}
                  &nbsp;&nbsp;
                  <label for="html">Permanent</label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    id="css"
                    name="fav_language"
                    value="temp"
                    onChange={() => setTemp("temp")}
                    checked={temp === "temp" ? true : false}
                  />
                  &nbsp;&nbsp;
                  <label for="css">Temporary</label>
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    marginTop: "25px",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <label>From Date :</label> &nbsp;&nbsp;
                    <input
                      type="date"
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <br />
                  {temp === "temp" && (
                    <div style={{ width: "45%" }}>
                      <label>To Date : </label>&nbsp;&nbsp;
                      <input
                        type="date"
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                <br />
                <div style={{ marginTop: "15px" }}>
                  <label>Set Geo Fencing Range (in meter) :</label>&nbsp;&nbsp;
                  <input
                    type="number"
                    style={{ borderRadius: "5px", border: "1px solid #CCCCCC" }}
                    onChange={(e) => setRad(parseInt(e.target.value))}
                    value={rad}
                  />
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 15px",
                      backgroundColor: "#3298DB",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                    }}
                    onClick={() => HandleLocSub()}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid white",
                  width: "50%",
                  height: "75vh",
                }}
              >
                <GeoMap
                  center={center}
                  // center={{ lat: 19.11578, lng: 72.847511 }}
                  zoom={14}
                  places={radius1}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA5qtWPQOOw4JvhnyRtJS8BLJEKLqjYTg8"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        sx={{ width: "100%" }}
        open={openloc}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose}
          >
            <img src={closeIcon} />
          </button>

          <div className="App-container" style={{}}>
            <div className="card">
              <div className="card-header">
                <h4 className="title">Add Location for Geo Fencing</h4>
              </div>

              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "start",
                    textAlign: "center",
                    gap: "10px",
                    marginTop: "16px",
                  }}
                >
                  <div
                    className="label"
                    style={{
                      color: "#4f4f4f",
                      float: "left",
                      fontWeight: "500",
                      marginRight: "30px",
                      width: "35%",
                    }}
                  >
                    Name of Location :{" "}
                  </div>
                  <input
                    type="text"
                    // name="file"
                    // accept=".zip,.rar,.7zip"
                    // onChange={handleFileChange}
                    style={{
                      border: "1px solid #cccccc",
                      borderRadius: "5px",
                      width: "200px",
                      padding: "5px 10px",
                    }}
                    onChange={(e) =>
                      setLocValue({ ...locValue, name: e.target.value })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "start",
                    textAlign: "center",
                    gap: "10px",
                    marginTop: "16px",
                  }}
                >
                  <div
                    style={{
                      color: "#4f4f4f",
                      float: "left",
                      fontWeight: "500",
                      marginRight: "30px",
                      width: "35%",
                    }}
                  >
                    Latitude :{" "}
                  </div>
                  <input
                    type="text"
                    // name="file"
                    // accept=".zip,.rar,.7zip"
                    style={{
                      border: "1px solid #cccccc",
                      borderRadius: "5px",
                      width: "200px",
                      padding: "5px 10px",
                    }}
                    onChange={(e) =>
                      setLocValue({ ...locValue, lat: e.target.value })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "start",
                    // textAlign: "center",
                    gap: "10px",
                    marginTop: "16px",
                  }}
                >
                  <div
                    style={{
                      color: "#4f4f4f",
                      float: "left",
                      fontWeight: "500",
                      marginRight: "30px",
                      width: "35%",
                    }}
                  >
                    longitude :{" "}
                  </div>
                  <input
                    type="text"
                    // name="file"
                    // accept=".zip,.rar,.7zip"
                    style={{
                      border: "1px solid #cccccc",
                      borderRadius: "5px",
                      width: "200px",
                      padding: "5px 10px",
                    }}
                    // onChange={handleFileChange}
                    onChange={(e) =>
                      setLocValue({ ...locValue, long: e.target.value })
                    }
                  />
                </div>

                {/* {isSuccess ? <p className="success-message" style={{color:"green"}}>Validation successful</p> : null} */}
                {/* <p className="error-message" style={{color:"red"}}>{errorMsg}</p> */}
                <br />
                <br />

                <button
                  style={{
                    padding: "5px 15px",
                    border: "none",
                    borderRadius: "8px",
                    backgroundColor: "#3298DB",
                    color: "white",
                  }}
                  onClick={() => handleAddlocation()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default GeoFencingReg;
