import React, { Component, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../pages/Sidebar/sidebar";
import Header from "../../pages/Sidebar/header";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import download from "../../assets/img/profiles/downloadicon.svg";
import { ConfigProvider } from "antd";
import { Table } from "ant-table-extensions";
import { useReactToPrint } from "react-to-print";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import empty from "../../assets/img/profiles/browser.png";
import {
  Box,
  TableContainer,
  Table as TableOne,
  TableRow,
  TableCell,
  Typography,
  Button,
  Toolbar,
  Grid,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";

const SButton = styled(Button)({
  background: "#2DB8C5",
  borderRadius: "4px",
  textTransform: "none",
  boxShadow: "none",
  padding: "8px",
  ":hover": {
    background: "#2DB8C5",
    boxShadow: "none",
  },
});

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No reimbursement data to show</p>
  </div>
);

const Reimbursement = () => {
  const [menu, setMenu] = useState(false);
  const [calcToggle, setCalcToggle] = useState(true);
  const [editReimburse, setEditReimburse] = useState({});
  const token = sessionStorage.getItem("access_token");
  const [loading, setLoading] = useState(true);
  const [reimbursementsType, setReimbursementsType] = useState([]);
  const [reimburseData, setReimburseData] = useState([]);
  const [reimBill, setReimBill] = useState(null);
  const [reimId, setReimId] = useState(null);
  const [claimData, setClaimData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [reportData, setReportData] = useState({});
  const [month, setMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const inputRef = useRef(null);
  const printPage = useReactToPrint({
    content: () => inputRef.current,
    documentTitle: "Reimburseement Report",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [applyReimburse, setApplyReimburse] = useState({
    name: { label: null, value: null },
    claim_allowed: 0,
    claim_applied: 0,
    bill: null,
    additional_note: "",
  });
  const [toggle, setToggle] = useState("saved");
  const [step, setStep] = useState(0);
  const [type, setType] = useState("");
  const reimType = [
    { label: "Outstation Travel Claim", value: "Outstation Travel Claim" },
    { label: "Local Travel Claim", value: "Local Travel Claim" },
  ];
  const [tripReimbursement, setTripReimbursement] = useState([
    {
      name: { label: null, value: null },
      claim_allowed: 0,
      claim_applied: 0,
      desc: "",
      bill: null,
      comp: [],
    },
  ]);
  const [editToggle, setEditToggle] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [dataSaved, setDataSaved] = useState([]);
  const [data, setData] = useState([]);
  // useEffect(() => {
  //   if (step === 2) {
  //     axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
  //       params: {
  //         reim_type: reimbursementsType[1].value,
  //         empid: JSON.parse(sessionStorage.getItem("emp_id")),
  //         toggle: "r2",
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setReimburseData(
  //         res.data.map((ele) => ({
  //           ...ele,
  //           value: ele.type === "number" ? 0 : null,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   }
  // },[step])

  // const handleReimbursement = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/reimbursement/`,
  //       // {
  //       //   email: sessionStorage.getItem("user"),
  //       // },
  //       {
  //         params: {
  //           empid: JSON.parse(sessionStorage.getItem("emp_id")),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setData(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const generateClaim = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/update_claim/`, {
        group_id: [
          ...new Set(
            dataSaved
              .filter((ele) => selectedRowKeys.includes(ele.reim_id))
              .map((item) => item.group_id)
          ),
        ],
      })
      .then((res) => {
        setSelectedRowKeys([]);
        setData([]);
        setDataSaved([]);
        handleSavedReimbursement();
        Swal.fire({
          icon: "success",
          text: "Your reimbursements sent for approval successfully.",
          // timer: 1500,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong one",
          });
        }
      });
  };

  const handleSavedReimbursement = () => {
    setLoading(true);
    setData([]);
    setDataSaved([]);
    setSelectedRowKeys([]);
    if (toggle === "applied") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/reim_emp_cal/`, {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            year: month.split("-")[0],
            month: month.split("-")[1],
          },
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        })
        .then((res) => {
          //setData(res.data);
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (toggle === "saved") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/reim_claim_group_cal/`, {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            year: month.split("-")[0],
            month: month.split("-")[1],
            claim_id: null,
          },
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        })
        .then((res) => {
          setDataSaved(res.data);
          // setDataSaved([
          //   ...new Map(res.data.map((item) => [item.group_id, item])).values(),
          // ]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setData([]);
    setDataSaved([]);
    handleSavedReimbursement();
  }, [toggle, month]);

  const handleClaimBundle = (e, id) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reim_claim_group_cal/`, {
        params: {
          claim_id: id,
        },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        //setData(res.data);
        setClaimData(res.data);
        setLoading(false);
        setStep(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGroupBundle = (e, id) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reim_claim_group_cal/`, {
        params: {
          group_id: id,
        },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        //setData(res.data);
        setGroupData(res.data);
        setLoading(false);
        setStep(2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   if (reimburseData?.length === 0) {
  //     setReimburseFields(false);
  //   } else {
  //     setReimburseFields(
  //       reimburseData
  //         ?.map((v) => Object.values(v))
  //         .map((v) => v.toString())
  //         .some((v) => v.slice(-1) === ",")
  //     );
  //   }
  // }, [reimburseData]);
  const handleCleanModal = () => {
    if (!calcToggle) {
      setApplyReimburse({
        name: { label: null, value: null },
        claim_allowed: 0,
        claim_applied: 0,
        bill: null,
        additional_note: "",
      });
      setReimburseData([]);
    }
  };

  const handleReimbursementBill = (id) => {
    let formdata = new FormData();
    formdata.append("curr_reim_id", id);
    formdata.append("empid", sessionStorage.getItem("emp_id"));
    formdata.append("bill", reimBill);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, formdata)
      .then((res) => {
        setReimBill(null);
        Swal.fire({
          icon: "success",
          text: "Your reimbursement sent for approval successfully.",
          // timer: 1500,
        });
        setApplyReimburse({
          name: { label: null, value: null },
          claim_allowed: 0,
          claim_applied: 0,
          bill: null,
          additional_note: "",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong two",
          });
        }
      });
  };

  const handleTripReimbursementBill = (obj) => {
    let formdata = new FormData();
    formdata.append("curr_reim_id", obj.id);
    formdata.append("empid", sessionStorage.getItem("emp_id"));
    formdata.append("bill", obj.bill);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, formdata)
      .then((res) => {})
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong two",
          });
        }
      });
  };

  const handleApplyReimburse = (e) => {
    e.preventDefault();
    // if (
    //   applyReimburse.name.label === "Food/Meal Reimbursement" &&
    //   reimburseData.filter((ele) => ele.name === "With Bill")[0].value === 1
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please upload reimbursement bill or uncheck the With Bill checkbox field if you are uploading without your claim will calculate ad 75% ",
    //     // timer: 1500,
    //   });
    // } else
    if (
      !applyReimburse.name.label ||
      applyReimburse.claim_applied == 0 ||
      Number(applyReimburse.claim_applied) >
        Number(applyReimburse.claim_allowed)
      // reimburseFields === true
    ) {
      Swal.fire({
        icon: "error",
        text: "Please enter valid claim applied amount,claim applied must greater than 0 and less than or equal to claim allowed. ",
        // timer: 1500,
      });
    } else {
      // var formData = new FormData();
      // formData.append("empid", applyReimburse.emp_id);
      // formData.append("name", applyReimburse.name.label);
      // formData.append("amount", applyReimburse.amount);
      // formData.append("bill", applyReimburse.bill);
      // formData.append("additional_note", applyReimburse.additional_note);
      // formData.append("max_amount", applyReimburse.amount);

      // reimburseData.forEach((value, index, array) => {
      //   formData.append(value.name, value.value);
      // });
      // for (var [key, value] of formData.entries()) {
      //   //console.log(key, value);
      // }
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
          calc_toggle: false,
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          reim_type: applyReimburse.name.value,
          claim_allowed: Number(applyReimburse.claim_allowed),
          claim_applied: Number(applyReimburse.claim_applied),
          additional_note: applyReimburse.additional_note,
          comp: reimburseData.map((ele) => ({
            reim_comp_id: ele.reim_comp_id,
            name: ele.name,
            type: ele.type,
            reim_id: ele.reim_id,
            toggle: ele.toggle,
            value:
              ele.toggle === "multiplier"
                ? Number(ele.value)
                : ele.toggle === "add"
                ? Number(ele.value)
                : ele.value,
          })),
        })
        .then((res) => {
          handleReimbursementBill(res.data.curr_reim_id);

          // setCalcToggle(
          //   res.data.claim_allowed &&
          //     reimburseData.forEach((value, index, array) =>
          //       value.value ? true : false
          //     )
          //     ? false
          //     : true
          // );

          // res.data.claim_allowed
          //   ? Swal.fire({
          //       icon: calcToggle ? "none" : "success",
          //       text: calcToggle
          //         ? ` ${res.data.claim_allowed} is your allowed claim `
          //         : "Reimbursement Request Successful",
          //       timer: 1500,
          //     })
          //   : "";

          handleCleanModal();
          handleSavedReimbursement();
          // handleReimbursement();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong one",
            });
          }
        });
    }
  };

  const handleApplyTripReimburse = (e) => {
    e.preventDefault();
    if (
      tripReimbursement.filter((ele) => ele.name.label === "").length > 0 ||
      tripReimbursement.filter((ele) => ele.claim_applied === 0).length > 0 ||
      tripReimbursement.filter((ele) => ele.claim_applied > ele.claim_allowed)
        .length > 0
    ) {
      Swal.fire({
        icon: "error",
        text: "Please enter valid claim applied amount,claim applied must greater than 0 and less than or equal to claim allowed. ",
        // timer: 1500,
      });
    } else if (type.value === "") {
      Swal.fire({
        text: "Please enter the reimbursement type",
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/trip_reimbursement/`, {
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          reim_head: type.value,
          travel: tripReimbursement
            .filter(
              (item) =>
                item.name.label === "Outstation Travel Claim" ||
                item.name.label === "Local Travel Claim"
            )
            .map((ele) => ({
              calc_toggle: false,
              empid: JSON.parse(sessionStorage.getItem("emp_id")),
              reim_type: ele.name.value,
              claim_allowed: ele.claim_allowed,
              claim_applied: ele.claim_applied,
              additional_note: ele.desc,
              comp: ele.comp.map((v) => ({
                reim_comp_id: v.reim_comp_id,
                name: v.name,
                type: v.type,
                reim_id: v.reim_id,
                toggle: v.toggle,
                value:
                  v.toggle === "multiplier"
                    ? Number(v.value)
                    : v.toggle === "add"
                    ? Number(v.value)
                    : v.value,
              })),
            })),

          food: tripReimbursement
            .filter((item) => item.name.label === "Food/Meal Reimbursement")
            .map((ele) => ({
              calc_toggle: false,
              empid: JSON.parse(sessionStorage.getItem("emp_id")),
              reim_type: ele.name.value,
              claim_allowed: ele.claim_allowed,
              claim_applied: ele.claim_applied,
              additional_note: ele.desc,
              comp: ele.comp.map((v) => ({
                reim_comp_id: v.reim_comp_id,
                name: v.name,
                type: v.type,
                reim_id: v.reim_id,
                toggle: v.toggle,
                value:
                  v.toggle === "multiplier"
                    ? Number(v.value)
                    : v.toggle === "add"
                    ? Number(v.value)
                    : v.value,
              })),
            })),

          boarding: tripReimbursement
            .filter((item) => item.name.label === "Boarding and Lodging")
            .map((ele) => ({
              calc_toggle: false,
              empid: JSON.parse(sessionStorage.getItem("emp_id")),
              reim_type: ele.name.value,
              claim_allowed: ele.claim_allowed,
              claim_applied: ele.claim_applied,
              additional_note: ele.desc,
              comp: ele.comp.map((v) => ({
                reim_comp_id: v.reim_comp_id,
                name: v.name,
                type: v.type,
                reim_id: v.reim_id,
                toggle: v.toggle,
                value:
                  v.toggle === "multiplier"
                    ? Number(v.value)
                    : v.toggle === "add"
                    ? Number(v.value)
                    : v.value,
              })),
            })),

          other: tripReimbursement
            .filter(
              (item) =>
                item.name.label === "Expense Reimbursement" ||
                item.name.label === "Other Reimbursement"
            )
            .map((ele) => ({
              calc_toggle: false,
              empid: JSON.parse(sessionStorage.getItem("emp_id")),
              reim_type: ele.name.value,
              claim_allowed: ele.claim_allowed,
              claim_applied: ele.claim_applied,
              additional_note: ele.desc,
              comp: ele.comp.map((v) => ({
                reim_comp_id: v.reim_comp_id,
                name: v.name,
                type: v.type,
                reim_id: v.reim_id,
                toggle: v.toggle,
                value:
                  v.toggle === "multiplier"
                    ? Number(v.value)
                    : v.toggle === "add"
                    ? Number(v.value)
                    : v.value,
              })),
            })),
        })
        .then((res) => {
          let t = res.data.travel.map((ele, index) => ({
            id: ele,
            bill: tripReimbursement.filter(
              (item) =>
                item.name.label === "Outstation Travel Claim" ||
                item.name.label === "Local Travel Claim"
            )[index].bill,
          }));
          let f = res.data.food.map((ele, index) => ({
            id: ele,
            bill: tripReimbursement.filter(
              (item) => item.name.label === "Food/Meal Reimbursement"
            )[index].bill,
          }));

          let b = res.data.boarding.map((ele, index) => ({
            id: ele,
            bill: tripReimbursement.filter(
              (item) => item.name.label === "Boarding and Lodging"
            )[index].bill,
          }));

          let o = res.data.other.map((ele, index) => ({
            id: ele,
            bill: tripReimbursement.filter(
              (item) =>
                item.name.label === "Expense Reimbursement" ||
                item.name.label === "Other Reimbursement"
            )[index].bill,
          }));

          let final = t.concat(f, b, o);

          for (const element of final) {
            handleTripReimbursementBill(element);
          }

          setTripReimbursement([
            {
              name: { label: null, value: null },
              claim_allowed: 0,
              claim_applied: 0,
              desc: "",
              bill: null,
              comp: [],
            },
          ]);
          setData([]);
          handleSavedReimbursement();
          Swal.fire({
            icon: "success",
            text: "Reimbursement successfully saved",
          });
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong one",
            });
          }
        });
    }
  };

  const handleEditReimburse = (e) => {
    e.preventDefault();
    // if (
    //   applyReimburse.name.label === "Food/Meal Reimbursement" &&
    //   reimburseData.filter((ele) => ele.name === "With Bill")[0].value === 1
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please upload reimbursement bill or uncheck the With Bill checkbox  field if you are uploading without your claim will calculate ad 75% ",
    //     // timer: 1500,
    //   });
    // } else
    if (
      editReimburse.claim_applied == 0 ||
      Number(editReimburse.claim_applied) > Number(editReimburse.claim_allowed)
      // reimburseFields === true
    ) {
      Swal.fire({
        icon: "error",
        text: "Please enter valid claim applied amount,claim applied must greater than 0 and less than or equal to claim allowed. ",
        // timer: 1500,
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
          calc_toggle: false,
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          reim_type: reimburseData[0]?.reim_id,
          reim_id: editReimburse.id,
          claim_allowed: Number(editReimburse.claim_allowed),
          claim_applied: Number(editReimburse.claim_applied),
          additional_note: editReimburse.additional_note,
          comp: reimburseData.map((ele) => ({
            reim_comp_id: ele.reim_comp_id,
            name: ele.name,
            type: ele.type,
            reim_id: ele.reim_id,
            toggle: ele.toggle,
            value:
              ele.toggle === "multiplier"
                ? Number(ele.value)
                : ele.toggle === "add"
                ? Number(ele.value)
                : ele.value,
          })),
        })
        .then((res) => {
          handleReimbursementBill(res.data.curr_reim_id);
          handleCleanModal();
          // handleReimbursement();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong one",
            });
          }
        });
    }
  };

  const handleClaimAmt = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        calc_toggle: true,
        empid: JSON.parse(sessionStorage.getItem("emp_id")),
        reim_type: applyReimburse.name.value,
        claim_allowed: Number(applyReimburse.claim_allowed),
        claim_applied: Number(applyReimburse.claim_applied),
        additional_note: applyReimburse.additional_note,
        comp: reimburseData.map((ele) => ({
          reim_comp_id: ele.reim_comp_id,
          name: ele.name,
          type: ele.type,
          reim_id: ele.reim_id,
          toggle: ele.toggle,
          value:
            ele.toggle === "multiplier"
              ? Number(ele.value)
              : ele.toggle === "add"
              ? Number(ele.value)
              : ele.value,
        })),
      })
      .then((res) => {
        setApplyReimburse({
          ...applyReimburse,
          claim_allowed: res.data.claim_allowed,
          claim_applied: res.data.claim_allowed,
        });
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   text: "Something went wrong.",
        //   timer: 1500,
        // });
      });
  };
  const handleEditClaimAmt = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        calc_toggle: true,
        empid: JSON.parse(sessionStorage.getItem("emp_id")),
        reim_type: reimburseData[0]?.reim_id,
        claim_allowed: Number(editReimburse.claim_allowed),
        claim_applied: Number(editReimburse.claim_applied),
        additional_note: editReimburse.additional_note,
        comp: reimburseData.map((ele) => ({
          reim_comp_id: ele.reim_comp_id,
          name: ele.name,
          type: ele.type,
          reim_id: ele.reim_id,
          toggle: ele.toggle,
          value:
            ele.toggle === "multiplier"
              ? Number(ele.value)
              : ele.toggle === "add"
              ? Number(ele.value)
              : ele.value,
        })),
      })
      .then((res) => {
        setEditReimburse({
          ...editReimburse,
          claim_allowed: res.data.claim_allowed,
          claim_applied: res.data.claim_allowed,
        });
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   text: "Something went wrong.",
        //   timer: 1500,
        // });
      });
  };

  const handleTripClaimAmt = (i) => {
    let trip = tripReimbursement[i];
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        calc_toggle: true,
        empid: JSON.parse(sessionStorage.getItem("emp_id")),
        reim_type: trip.name.value,
        claim_allowed: Number(trip.claim_allowed),
        claim_applied: Number(trip.claim_applied),
        additional_note: trip.desc,
        comp: trip.comp.map((ele) => ({
          reim_comp_id: ele.reim_comp_id,
          name: ele.name,
          type: ele.type,
          reim_id: ele.reim_id,
          toggle: ele.toggle,
          value:
            ele.toggle === "multiplier"
              ? Number(ele.value)
              : ele.toggle === "add"
              ? Number(ele.value)
              : ele.value,
        })),
      })
      .then((res) => {
        let claim = [...tripReimbursement];
        claim[i].claim_allowed = res.data.claim_allowed;
        claim[i].claim_applied = res.data.claim_allowed;
        setTripReimbursement(claim);
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   text: "Something went wrong.",
        //   timer: 1500,
        // });
      });
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current   function is done)

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
  //       params: { coregid: sessionStorage.getItem("coid") },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setReimbursements(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        params: {
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          toggle: "r1",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReimbursementsType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setReimburseData([]);
    setApplyReimburse({
      ...applyReimburse,
      bill: null,
      claim_allowed: 0,
      claim_applied: 0,
      additional_note: "",
    });
    setCalcToggle(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        params: {
          reim_type: applyReimburse.name.value,
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          toggle: "r2",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReimburseData(
          res.data.map((ele) => ({
            ...ele,
            value: ele.type === "number" ? 0 : null,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [applyReimburse.name]);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
  //       params: {
  //         reim_type: reimId,
  //         empid: JSON.parse(sessionStorage.getItem("emp_id")),
  //         toggle: "r2",
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       let data = [...tripReimbursement];
  //       data[data.length - 1]["comp"] = res.data.map((ele) => ({
  //         ...ele,
  //         value: ele.type === "number" ? 0 : null,
  //       }));
  //       setTripReimbursement(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [reimId]);

  const handleMappedReim = (index, id) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        params: {
          reim_type: id,
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          toggle: "r2",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let data = [...tripReimbursement];
        data[index]["comp"] = res.data.map((ele) => ({
          ...ele,
          value: ele.type === "number" ? 0 : null,
        }));
        data[index]["claim_allowed"] = 0;
        data[index]["claim_applied"] = 0;
        data[index]["desc"] = "";
        setTripReimbursement(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddFields = (e) => {
    e.preventDefault();
    let newfield = {
      name: { label: null, value: null },
      claim_allowed: 0,
      claim_applied: 0,
      desc: "",
      comp: [],
    };
    setTripReimbursement([...tripReimbursement, newfield]);
  };

  const RemoveField = (index) => {
    let data = [...tripReimbursement];
    data.splice(index, 1);
    setTripReimbursement(data);
  };

  // useEffect(() => {
  //   if (reimbursements.length > 0 && applyReimburse.name !== "") {
  //     const list = reimbursements?.find(
  //       (ele) => ele.id === applyReimburse.name?.value
  //     ); //=== projectName.value
  //     //console.log(list.map((ele) => ele.members.name));

  //     setReimburseData(
  //       list?.component.map((ele) => ({
  //         id: ele.id,
  //         name: ele.name,
  //         type: ele.type,
  //         option: ele.option,
  //         value: "",
  //       })) //.filter(ele => ele.name !== "Amount")
  //     );
  //     //console.log(empList.find((ele) => ele.id === projectName.value));
  //     // const list1 = list?.members.concat(list.teamleads);
  //     // const list2 = list1?.concat([list?.creator]);
  //   }
  // }, [applyReimburse.name]);

  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    data[index]["value"] = event.target.value;
    setState(data);
    if (editToggle) {
      handleEditClaimAmt(event);
    } else handleClaimAmt(event);
  };

  const handleFormChange12 = (index, event, state, setState) => {
    let data = [...state];
    data[index]["value"] = event === true ? 1 : 0;
    setState(data);
    handleClaimAmt(event);
    if (editToggle) {
      handleEditClaimAmt(event);
    } else handleClaimAmt(event);
  };

  const handleFormChange1 = (index, event, state, setState, value) => {
    if (editToggle) {
      let data = [...reimburseData];
      data[index]["value"] = event.value;
      if (editReimburse.name === "Outstation Travel Claim") {
        data[7]["value"] = 0;
      } else if (editReimburse.name === "Local Travel Claim") {
        data[2]["value"] = 0;
      } else if (editReimburse.name === "Local Travel") {
        data[2]["value"] = 0;
      }
      if (
        editReimburse.name === "Outstation Travel Claim" &&
        event.label === "Railway/Bus/Auto"
      ) {
        data[8]["value"] = 0;
      } else if (
        editReimburse.name === "Local Travel Claim" &&
        event.label === "Railway/Bus/Auto"
      ) {
        data[5]["value"] = 0;
      } else if (
        editReimburse.name === "Local Travel" &&
        event.label === "Railway/Bus/Auto"
      ) {
        data[5]["value"] = 0;
      }
      // data[index][event] = event.value;
      setState(data);
      handleEditClaimAmt(event);
    } else {
      let data = [...reimburseData];
      data[index]["value"] = event.value;
      if (applyReimburse.name.label === "Outstation Travel Claim") {
        data[7]["value"] = 0;
      } else if (applyReimburse.name.label === "Local Travel Claim") {
        data[2]["value"] = 0;
      } else if (applyReimburse.name.label === "Local Travel") {
        data[2]["value"] = 0;
      }
      if (
        applyReimburse.name.label === "Outstation Travel Claim" &&
        event.label === "Railway/Bus/Auto"
      ) {
        data[8]["value"] = 0;
      } else if (
        applyReimburse.name.label === "Local Travel Claim" &&
        event.label === "Railway/Bus/Auto"
      ) {
        data[5]["value"] = 0;
      } else if (
        applyReimburse.name.label === "Local Travel" &&
        event.label === "Railway/Bus/Auto"
      ) {
        data[5]["value"] = 0;
      }
      // data[index][event] = event.value;
      setState(data);
      handleClaimAmt(event);
    }
  };

  // trip reimbursements

  const handleFormChange11 = (index, event, state, setState) => {
    let data = [...state];
    data[index]["name"] = event;
    setState(data);
    setReimId(event.value);
    handleMappedReim(index, event.value);
    handleTripClaimAmt(index);
  };

  const handleFormChange333 = (index, event, value, state, setState) => {
    let data = [...state];
    data[index][value] = event.target.value;
    setState(data);
  };

  const handleFormChange334 = (index, event, value, state, setState) => {
    let data = [...state];
    data[index][value] = event.target.files[0];
    setState(data);
  };

  const handleFormChange111 = (index, i, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[i]["comp"][index]["value"] = event;
    // data[index][event] = event.value;
    setState(data);
    handleTripClaimAmt(i);
  };

  const handleFormChange321 = (index, i, event, state, setState) => {
    let data = [...state];
    data[i]["comp"][index]["value"] = event === true ? 1 : 0;
    setState(data);
    handleTripClaimAmt(i);
  };

  const handleFormChange222 = (index, i, event, state, setState) => {
    let data = [...state];
    data[i]["comp"][index]["value"] = event.value;
    if (data[i]["name"].label === "Outstation Travel Claim") {
      data[i]["comp"][7]["value"] = 0;
    } else if (data[i]["name"].label === "Local Travel Claim") {
      data[i]["comp"][2]["value"] = 0;
    } else if (data[i]["name"].label === "Local Travel") {
      data[i]["comp"][2]["value"] = 0;
    }
    if (
      data[i]["name"].label === "Outstation Travel Claim" &&
      event.label === "Railway/Bus/Auto"
    ) {
      data[i]["comp"][8]["value"] = 0;
    } else if (
      applyReimburse.name.label === "Local Travel Claim" &&
      event.label === "Railway/Bus/Auto"
    ) {
      data[i]["comp"][5]["value"] = 0;
    } else if (
      applyReimburse.name.label === "Local Travel" &&
      event.label === "Railway/Bus/Auto"
    ) {
      data[i]["comp"][5]["value"] = 0;
    }
    // data[index][event] = event.value;
    setState(data);
    handleTripClaimAmt(i);
  };

  const generateReport = (e, id) => {
    e.preventDefault();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/reimbursement_report_bgauss/`,
        {
          params: {
            empid: JSON.parse(sessionStorage.getItem("emp_id")),
            year: month.split("-")[0],
            month: month.split("-")[1],
            claim_id: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setReportData(res.data);
        setStep(2);
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          timer: 3000,
          text: "Report Generated",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        data: { reim_id: id },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Reimbursement Deleted Successfully",
        });
        // handleReimbursement();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Claim Id",
      dataIndex: "claim_id",
      key: "key",
      //   sorter: (a, b) => a.leaveleavetype.length - b.type.length,
    },
    {
      title: "Application Date",
      dataIndex: "application_date",
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Claim Allowed",
      dataIndex: "total_allowed",
      key: "key",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Claim Applied",
      dataIndex: "total_applied",
      key: "key",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Claim Approved",
      dataIndex: "total_approved",
      key: "key",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   align: "center",
    //   render: (text, record) => (
    //     <div className="action-label text-center">
    //       <a className="btn btn-white btn-sm btn-rounded">
    //         <i
    //           className={
    //             text === "Declined"
    //               ? "fa fa-dot-circle-o text-danger"
    //               : text === "Approved"
    //               ? "fa fa-dot-circle-o text-success"
    //               : "fa fa-dot-circle-o text-info"
    //           }
    //         />{" "}
    //         {text}
    //       </a>
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <>
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            className="submit-btn1"
            onClick={(e) => {
              generateReport(e, text.claim_id);
            }}
          >
            Report
          </button>{" "}
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            className="submit-btn1"
            onClick={(e) => {
              handleClaimBundle(e, text.claim_id);
            }}
          >
            View
          </button>
        </>
      ),
      // text.status === "Pending" && (
      //   <>
      //     <button
      //       style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
      //       className="submit-btn1"
      //       data-bs-toggle="modal"
      //       data-bs-target="#edit_reim"
      //       onClick={(e) => {
      //         setEditReimburse(record);
      //         setReimburseData(record.reimbursement_edit_component);
      //         setEditToggle(true);
      //       }}
      //     >
      //       Edit
      //     </button>{" "}
      //     <button
      //       style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
      //       className="submit-btn1"
      //       onClick={(e) => handleDelete(text.id)}
      //     >
      //       Delete
      //     </button>
      //   </>
      // ),
    },
  ];

  const column1 = [
    {
      title: "Reimbursement Name",
      dataIndex: "reim_name",
    },
    {
      title: "Application Date",
      dataIndex: "date_creation",
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Claim Allowed",
      dataIndex: "claim_allowed",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Claim Applied",
      dataIndex: "claim_applied",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Claim Approved",
      dataIndex: "claim_approved",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Bill",
      dataIndex: "bill",
      key: "key",
      align: "center",
      // render: (text, record) =>
      //   (text !== "-"|| text!==null)? (
      //     <a
      //       href={(text !== "-"|| text!==null) ? text[0].links : ""}
      //       target="_blank"
      //       rel="noopener noreferrer"
      //     >
      //       <img
      //         src={download}
      //         style={{
      //           height: "18px",
      //           width: "18px",

      //           cursor: "pointer",
      //         }}
      //       />
      //     </a>
      //   ) : (
      //     <> Not Available</>
      //   ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   align: "center",
    //   render: (text, record) => (
    //     <div className="action-label text-center">
    //       <a className="btn btn-white btn-sm btn-rounded">
    //         <i
    //           className={
    //             text === "Declined"
    //               ? "fa fa-dot-circle-o text-danger"
    //               : text === "Approved"
    //               ? "fa fa-dot-circle-o text-success"
    //               : "fa fa-dot-circle-o text-info"
    //           }
    //         />{" "}
    //         {text}
    //       </a>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text, record) => (
    //     <button
    //         style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
    //         className="submit-btn1"
    //         data-bs-toggle="modal"
    //         data-bs-target="#edit_reim"
    //         onClick={(e) => {
    //           setEditReimburse(record);
    //           // setReimburseData(record.reimbursement_edit_component);
    //           // setReimburseData()
    //           setEditToggle(true);
    //         }}
    //       >
    //         Edit
    //       </button>
    //   ),
    // },
  ];

  const column2 = [
    {
      title: "Reimbursement Name",
      dataIndex: "reim_name",
    },
    {
      title: "Claim Allowed",
      dataIndex: "claim_allowed",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Claim Applied",
      dataIndex: "claim_applied",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Claim Approved",
      dataIndex: "claim_approved",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Bill",
      dataIndex: "bill",
      key: "key",
      align: "center",
      render: (text, record) =>
        text !== "-" ? (
          <a
            href={text !== "-" ? text[0].links : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={download}
              style={{
                height: "18px",
                width: "18px",

                cursor: "pointer",
              }}
            />
          </a>
        ) : (
          <> Not Available</>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-info"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },

    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text, record) => (
    //     <button
    //       style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
    //       className="submit-btn1"
    //       // onClick={(e) => {
    //       //   handleGroupBundle(e, text.group_id);
    //       // }}
    //     >
    //       View
    //     </button>
    //   ),
    // },
  ];

  // console.log(reimburseData, editReimburse);
  let currentdate = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - 2));
  // console.log(currentdate);
  // console.log(`${last3months.getDate()}/${last3months.getMonth()+1}/${last3months.getFullYear()}T${last3months.getHours}:${last3months.getMinutes}`)
  // console.log(last3months.toJSON());

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Reimbursements</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "6px 18px",
                      fontSize: "12px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "7px",
                    }}
                    onClick={() => {
                      setReimburseData([]);
                      setEditToggle(false);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_reimbursement"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp;Single Reimbursement
                  </button>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "6px 18px",
                      fontSize: "12px",
                      background: "#2B8F6F",
                      border: "none",
                      color: "white",
                      borderRadius: "7px",
                    }}
                    // onClick={() => {
                    //   setReimburseData([]);
                    //   setEditToggle(false);
                    // }}
                    data-bs-toggle="modal"
                    data-bs-target="#trip_reimbursement"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp;Trip Reimbursement
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  {step === 0 && (
                    <>
                      <div
                        className="col-auto"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "6px 20px",
                              fontSize: "15px",
                              borderRadius: "8px 0px 0px 8px",
                              background:
                                toggle === "saved" ? "#477BFF" : "#CCCCCC",
                              border: "none",
                              color: toggle === "saved" ? "#FFFFFF" : "#344767",
                            }}
                            onClick={() => {
                              setToggle("saved");
                            }}
                          >
                            {" "}
                            Saved as Draft
                          </button>
                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "6px 20px",
                              fontSize: "15px",
                              borderRadius: "0px 8px 8px 0px",
                              background:
                                toggle === "applied" ? "#477BFF" : "#CCCCCC",
                              border: "none",
                              color:
                                toggle === "applied" ? "#FFFFFF" : "#344767",
                            }}
                            onClick={() => {
                              setToggle("applied");
                            }}
                          >
                            Applied
                          </button>
                        </div>
                        {toggle === "saved" && selectedRowKeys.length > 0 && (
                          <div className="view-icons">
                            <button
                              className="btn btn-success btn-block "
                              style={{
                                padding: "6px 20px",
                                fontSize: "15px",
                                borderRadius: "8px",
                                background: "#477BFF",
                                border: "none",
                                color: "#FFFFFF",
                              }}
                              onClick={() => generateClaim()}
                            >
                              {" "}
                              Send for Approval
                            </button>
                          </div>
                        )}
                        <div className="view-icons">
                          <input
                            style={{
                              width: "150px",
                              borderRadius: "5px",
                              height: "30px",
                              padding: "10px",
                            }}
                            type="month"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="table-responsive">
                        <ConfigProvider
                          renderEmpty={
                            // customize ?
                            customizeRenderEmpty
                            // : undefined
                          }
                        >
                          {toggle === "applied" && (
                            <Table
                              className="table-striped"
                              pagination={{
                                total: data.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              columns={columns}
                              // bordered
                              dataSource={data}
                              // rowKey={(record) => record.id}
                              loading={loading}
                            />
                          )}
                          {toggle === "saved" && (
                            <Table
                              className="table-striped"
                              pagination={{
                                total: dataSaved.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              columns={column1}
                              // bordered
                              rowSelection={rowSelection}
                              dataSource={dataSaved}
                              rowKey={(record) => record.reim_id}
                              loading={loading}
                            />
                          )}
                        </ConfigProvider>
                      </div>
                    </>
                  )}
                  {step === 1 && (
                    <>
                      <div
                        className="col-auto"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "6px 20px",
                              fontSize: "15px",
                              borderRadius: "8px",
                              background: "#477BFF",
                              border: "none",
                              color: "#FFFFFF",
                            }}
                            onClick={() => {
                              setStep(0);
                            }}
                          >
                            {" "}
                            Back
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <ConfigProvider
                          renderEmpty={
                            // customize ?
                            customizeRenderEmpty
                            // : undefined
                          }
                        >
                          <Table
                            className="table-striped"
                            pagination={{
                              total: claimData.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            columns={column2}
                            // bordered
                            dataSource={claimData}
                            rowKey={(record) => record.id}
                          />
                        </ConfigProvider>
                      </div>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ width: "100%", m: 0, ml: 0 }}>
                          {/* <div
                    className="content container-fluid"
                    style={{
                      marginTop: "23px",
                      padding: "8px",
                      paddingLeft: "18px",
                    }}
                  >
                    <div className="page-header">
                      <h3 className="page-title">Payslip</h3>
                    </div>
                  </div> */}

                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                              ml: "auto",
                              mr: "auto",
                              p: 0,
                            }}
                          >
                            <div className="col-md-3">
                              <button
                                className="btn btn-success btn-block "
                                style={{
                                  padding: "6px 20px",
                                  fontSize: "15px",
                                  borderRadius: "8px",
                                  background: "#477BFF",
                                  border: "none",
                                  color: "#FFFFFF",
                                }}
                                onClick={() => {
                                  setStep(0);
                                }}
                              >
                                {" "}
                                Back
                              </button>
                            </div>

                            <SButton onClick={printPage}>
                              <Typography
                                variant="body2"
                                sx={{ color: "#FFFFFF" }}
                              >
                                Download
                              </Typography>
                            </SButton>
                          </Box>

                          <Box
                            sx={{
                              background: "#FFFFFF",
                              borderRadius: "4px",
                            }}
                          >
                            <Box
                              ref={inputRef}
                              sx={{
                                textAlign: "left",
                                // p: 1.5,
                                // ml: "auto",
                                // mr: "auto",
                              }}
                            >
                              <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                                <br />
                                <br />
                                <span>
                                  <b>Employee Name : </b> {reportData?.emp_name}
                                </span>
                                <br />
                                <br />
                                <span>
                                  <b>Designation : </b>
                                  {reportData?.desg}
                                </span>
                                <br />
                                <br />
                                <span>
                                  <b>Grade : </b>
                                  {reportData?.grade}
                                </span>
                                <br />
                                <br />
                                <span>
                                  <b>Department : </b>
                                  {reportData?.dept}
                                </span>
                                <br />
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>
                                    <b>Duration : </b>
                                    {reportData?.claim_duration}
                                  </span>{" "}
                                  <span>
                                    <b>Claim id : </b>
                                    {reportData?.claim_id}
                                  </span>
                                </div>
                              </Box>
                              <Box sx={{ height: "100%", p: 1 }}>
                                <table className="reimbursement-report">
                                  <tr>
                                    <th>From Date</th>
                                    <th>To Date</th>
                                    <th>Classification of Travel</th>
                                    <th>Travel Mode Share Taxi/Auto</th>
                                    <th>Bus/Train/Flight/Hired Taxi</th>
                                    <th>Own Car/Bike</th>
                                    <th>Food Expense</th>
                                    <th>Lodging/Self Stay Accommodation</th>
                                    <th>Toll/Tax/Parking</th>
                                    <th>Expense Reimbursement</th>
                                  </tr>
                                  {reportData?.data?.map((ele) => (
                                    <tr>
                                      <td style={{ textAlign: "left" }}>
                                        {ele.from_date
                                          ?.slice(0, 10)
                                          ?.split("-")
                                          ?.reverse()
                                          ?.join("/")}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {ele.to_date
                                          ?.slice(0, 10)
                                          ?.split("-")
                                          ?.reverse()
                                          ?.join("/")}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {ele.classification}
                                      </td>
                                      <td>{ele.shared_taxi}</td>
                                      <td>{ele.bus_train_flight}</td>
                                      <td>{ele.own_car_bike}</td>
                                      <td>{ele.food_expense}</td>
                                      <td>{ele.stay_accomodation}</td>
                                      <td>{ele.toll_parking}</td>
                                      <td>{ele.expenses}</td>
                                    </tr>
                                  ))}
                                </table>
                              </Box>
                              <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                                <br />
                                <span>
                                  <b>Total Claim Amount(₹) : </b>
                                  {reportData?.total_claim}
                                </span>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  p: 1,
                                  pt: 0,
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div>Employee Name</div>
                                <div>HOD Name</div>
                                <div>Finance Approval</div>
                              </Box>
                              <br />
                              <br />
                              <br />
                              <br />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <div
          id="add_reimbursement"
          className="modal custom-modal fade"
          role="dialog"
          //data-bs-backdrop="static"
          //data-bs-keyboard="false"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Apply for Reimbursement</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-3">
                      <label>
                        Name of Reimbursement
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        // options={[
                        //   ...new Set(reimbursements?.map((item) => item)),
                        // ].map((ele) => ({
                        //   label: ele.name,
                        //   value: ele.id,
                        // }))}
                        placeholder={<div>Type to search</div>}
                        options={reimbursementsType}
                        value={applyReimburse.name}
                        onChange={(e) =>
                          setApplyReimburse({ ...applyReimburse, name: e })
                        }
                      />
                    </div>
                    {/* <div className="form-group col-md-6">
                      <label>
                        Upload Bill{" "}
                        <span style={{ fontWeight: "300", fontSize: "10px" }}>
                          (PDF only)
                        </span>
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        name="file"
                        accept="application/pdf"
                        //value={applyReimburse.bill}
                        style={{ height: "38px", maxWidth: "100%" }}
                        onChange={(event) =>
                          setApplyReimburse({
                            ...applyReimburse,
                            bill: event.target.files[0],
                          })
                        }
                      />
                    </div> */}
                    {/* <div className="form-group col-md-4">
                      <label>
                        Amount
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={applyReimburse.amount}
                        onChange={(e) =>
                          setApplyReimburse({
                            ...applyReimburse,
                            amount: e.target.value,
                          })
                        }
                      />
                    </div> */}

                    {reimburseData?.map((ele, index) => (
                      <>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {ele.name === "Distance Travelled"
                                ? "Distance Travelled (km)"
                                : ele.name === "Other Origin City"
                                ? reimburseData[2].value === 95 ||
                                  reimburseData[2]?.value === 143 ||
                                  reimburseData[2]?.value === 287 ||
                                  reimburseData[2]?.value === 335
                                  ? "Name of Origin City"
                                  : null
                                : ele.name === "Other Destination City"
                                ? reimburseData[4]?.value === 95 ||
                                  reimburseData[4]?.value === 143 ||
                                  reimburseData[4]?.value === 287 ||
                                  reimburseData[4]?.value === 335
                                  ? "Name of Destination City"
                                  : null
                                : ele.name === "Other City"
                                ? reimburseData[3]?.value === 95 ||
                                  reimburseData[0]?.value === 95 ||
                                  reimburseData[3]?.value === 143 ||
                                  reimburseData[0]?.value === 143
                                  ? "Name of City"
                                  : null
                                : ele.name}
                              {ele.required === 1 && (
                                <span className="text-danger">*</span>
                              )}
                            </label>
                            {(ele.type === "date" ||
                              ele.type === "datetime-local") && (
                              <input
                                className="form-control"
                                min={last3months
                                  .toISOString()
                                  .slice(
                                    0,
                                    last3months.toISOString().lastIndexOf(":")
                                  )}
                                max={moment().format("YYYY-MM-DDThh:mm")}
                                type={ele.type}
                                value={ele.value}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    reimburseData,
                                    setReimburseData
                                  )
                                }
                              />
                            )}

                            {ele.type === "text" &&
                              ele.name !== "Other Origin City" &&
                              ele.name !== "Other City" &&
                              ele.name !== "Other Destination City" && (
                                <input
                                  className="form-control"
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}
                            {ele.type === "boolean" && (
                              <input
                                style={{ marginTop: "5px" }}
                                // className="form-control"
                                type="checkbox"
                                // value={ele.value}
                                checked={ele.value === 1 ? true : false}
                                onChange={(event) => {
                                  handleFormChange12(
                                    index,
                                    ele.value === 0 ? true : false,
                                    reimburseData,
                                    setReimburseData
                                  );
                                }}
                              />
                            )}
                            {ele.name == "Other Origin City" &&
                              (reimburseData[2]?.value === 95 ||
                                reimburseData[2]?.value === 143 ||
                                reimburseData[2]?.value === 287 ||
                                reimburseData[2]?.value === 335) && (
                                <input
                                  className="form-control"
                                  disabled={
                                    reimburseData[2].value === 95 ||
                                    reimburseData[2]?.value === 143 ||
                                    reimburseData[2]?.value === 287 ||
                                    reimburseData[2]?.value === 335
                                      ? false
                                      : true
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}

                            {ele.name == "Other City" &&
                              (reimburseData[3]?.value === 95 ||
                                reimburseData[3]?.value === 143) && (
                                <input
                                  className="form-control"
                                  disabled={
                                    reimburseData[3]?.value === 95 ||
                                    reimburseData[3]?.value === 143
                                      ? false
                                      : true
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}
                            {ele.name == "Other City" &&
                              (reimburseData[0]?.value === 95 ||
                                reimburseData[0]?.value === 143) && (
                                <input
                                  className="form-control"
                                  disabled={
                                    reimburseData[0]?.value === 95 ||
                                    reimburseData[0]?.value === 143
                                      ? false
                                      : true
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}

                            {ele.name === "Other Destination City" &&
                              (reimburseData[4]?.value === 95 ||
                                reimburseData[4]?.value === 143 ||
                                reimburseData[4]?.value === 287 ||
                                reimburseData[4]?.value === 335) && (
                                <input
                                  className="form-control"
                                  type={ele.type}
                                  value={ele.value}
                                  disabled={
                                    reimburseData[4].value === 95 ||
                                    reimburseData[4]?.value === 143 ||
                                    reimburseData[4]?.value === 287 ||
                                    reimburseData[4]?.value === 335
                                      ? false
                                      : true
                                  }
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}
                            {ele.type === "number" &&
                              ele.name !== "Distance Travelled" &&
                              ele.name !== "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele?.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label ===
                              "Outstation Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  disabled={
                                    reimburseData[6]?.value === 7 ||
                                    reimburseData[6]?.value === 11 ||
                                    reimburseData[6]?.value === 9 ||
                                    reimburseData[6]?.value === 67 ||
                                    reimburseData[6]?.value === 71 ||
                                    reimburseData[6]?.value === 69
                                      ? false
                                      : true
                                  }
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele?.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label ===
                              "Inter-city Travel" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele?.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label ===
                              "Local Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  disabled={
                                    reimburseData[1]?.value === 19 ||
                                    reimburseData[1]?.value === 23 ||
                                    reimburseData[1]?.value === 21 ||
                                    reimburseData[1]?.value === 79 ||
                                    reimburseData[1]?.value === 83 ||
                                    reimburseData[1]?.value === 81
                                      ? false
                                      : true
                                  }
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label === "Local Travel" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[1]?.value === 124 ||
                                    reimburseData[1]?.value === 128
                                      ? false
                                      : true
                                  }
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label ===
                              "Local Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[1]?.value === 19 ||
                                    reimburseData[1]?.value === 23 ||
                                    reimburseData[1]?.value === 21 ||
                                    reimburseData[1]?.value === 79 ||
                                    reimburseData[1]?.value === 83 ||
                                    reimburseData[1]?.value === 81
                                      ? true
                                      : false
                                  }
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label === "Local Travel" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[1]?.value === 124 ||
                                    reimburseData[1]?.value === 128
                                      ? true
                                      : false
                                  }
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label ===
                              "Outstation Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[6]?.value === 7 ||
                                    reimburseData[6]?.value === 11 ||
                                    reimburseData[6]?.value === 9 ||
                                    reimburseData[6]?.value === 67 ||
                                    reimburseData[6]?.value === 71 ||
                                    reimburseData[6]?.value === 69
                                      ? true
                                      : false
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {applyReimburse.name.label ===
                              "Inter-city Travel" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {ele.type === "select" && (
                              <div>
                                <Select
                                  options={ele.option}
                                  value={ele.option}
                                  onChange={(event) => {
                                    handleFormChange1(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    );
                                  }}
                                />
                              </div>
                            )}

                            {ele.type === "option" && (
                              <div>
                                <Select
                                  options={ele.option}
                                  value={ele.option
                                    .map((ele) => ({
                                      label: ele.label,
                                      value: ele.value,
                                    }))
                                    .find((item) => item.value === ele.value)}
                                  onChange={(event) => {
                                    handleFormChange1(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    );
                                  }}
                                />
                              </div>
                            )}

                            {ele.type === "" && ele.name === "Date" && (
                              <div>
                                <input
                                  className="form-control"
                                  type="date"
                                  min={`${last3months.getFullYear()}-${
                                    last3months.getMonth() + 1
                                  }-${last3months.getDate()}`}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="form-group col-md-3">
                      <label>
                        Reimbursement Description
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={applyReimburse.desc}
                        onChange={(e) =>
                          setApplyReimburse({
                            ...applyReimburse,
                            desc: e.target.value,
                          })
                        }
                      />
                    </div>
                    {
                        sessionStorage.getItem("coid")!=="RAWJ225129" &&
                        <>
                    <div className="form-group col-md-3">
                      <label>
                        Claim Allowed{" "}
                        <span
                          style={{
                            fontSize: "11px",
                            fontWeight: "500",
                          }}
                        >
                          {applyReimburse.name.label ===
                            "Outstation Travel Claim" &&
                            `(Including daily allowance)`}
                        </span>
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={applyReimburse.claim_allowed}
                        onChange={(e) =>
                          setApplyReimburse({
                            ...applyReimburse,
                            claim_allowed: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label>
                        Claim Applied
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={applyReimburse.claim_applied}
                        onChange={(e) =>
                          setApplyReimburse({
                            ...applyReimburse,
                            claim_applied: e.target.value,
                          })
                        }
                        onWheel={(e) => numberInputOnWheelPreventChange(e)}
                      />
                    </div>
                   </>
}
                    <div className="form-group col-md-3">
                      <label>
                        {`Reimbursement Bill (Optional)`}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="file"
                        onChange={(e) => setReimBill(e.target.files[0])}
                      />
                    </div>

                    {/* {reimburseData?.map((ele, index) => (
                      <>
                        <div className=" col-md-6">
                          <div className="form-group">
                            <label>
                              {ele.name === "Distance" ||
                              ele.name === "distance"
                                ? distance
                                  ? `${
                                      ele.name[0].toUpperCase() +
                                      ele.name.slice(1)
                                    } in hr`
                                  : `${
                                      ele.name[0].toUpperCase() +
                                      ele.name.slice(1)
                                    } in km`
                                :
                                  ele.name[0].toUpperCase() + ele.name.slice(1)}
                              <span className="text-danger">*</span>
                            </label>
                            {(ele.type === "text" ||
                              ele.type === "date" ||
                              ele.type === "number") && (
                              <input
                                className="form-control"
                                type={ele.type}
                                value={ele.value}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    reimburseData,
                                    setReimburseData
                                  )
                                }
                              />
                            )}
                            {ele.type === "select" && (
                              <div>
                                <Select
                                  options={ele.option
                                    ?.split(",")
                                    .map((ele) => ({
                                      label: ele,
                                      value: ele,
                                    }))}
                                  value={ele.option
                                    ?.split(",")
                                    .map((ele) => ({
                                      label: ele,
                                      value: ele,
                                    }))
                                    .find((item) => item.value === ele.value)}
                                  onChange={(event) => {
                                    handleFormChange1(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    );
                                    event.value === "Flight" ||
                                    event.value === "flight"
                                      ? setDistance(true)
                                      : setDistance(false);
                                  }}
                                />
                              </div>
                            )}

                            {ele.type === "option" && (
                              <div>
                                <Select
                                  options={ele.option
                                    ?.split(",")
                                    .map((ele) => ({
                                      label: ele,
                                      value: ele,
                                    }))}
                                  value={ele.option
                                    ?.split(",")
                                    .map((ele) => ({
                                      label: ele,
                                      value: ele,
                                    }))
                                    .find((item) => item.value === ele.value)}
                                  onChange={(event) => {
                                    handleFormChange1(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    );
                                    event.value === "Flight" ||
                                    event.value === "flight"
                                      ? setDistance(true)
                                      : setDistance(false);
                                  }}
                                />
                              </div>
                            )}

                            {ele.type === "" && ele.name === "Date" && (
                              <div>
                                <input
                                  className="form-control"
                                  type="date"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))} */}
                  </div>

                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss={
                        !applyReimburse.name ||
                        !applyReimburse.claim_allowed ||
                        !applyReimburse.claim_applied ||
                        Number(applyReimburse.claim_applied) >
                          Number(applyReimburse.claim_allowed)
                          ? ""
                          : "modal"
                      }
                      style={{
                        background: "#329fd1",
                        width: "fit-content",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                        color: "white",
                      }}
                      onClick={(event) => handleApplyReimburse(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Trip Reimbursement */}

        <div
          id="trip_reimbursement"
          className="modal custom-modal fade"
          role="dialog"
          //data-bs-backdrop="static"
          //data-bs-keyboard="false"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5
                  className="modal-title"
                  style={{ textAlign: "start", alignItem: "left" }}
                >
                  <b>Trip Reimbursement</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div
                className="modal-body"
                style={{ minHeight: "600px", marginTop: "-30px" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group col-sm-3">
                      <label>
                        Reimbursement Type<span className="text-danger">*</span>
                      </label>
                      <Select
                        placeholder={<div>Type to search</div>}
                        options={reimType}
                        value={type}
                        onChange={(e) => setType(e)}
                      />
                    </div>
                    {tripReimbursement.map((v, i) => (
                      <>
                        <div
                          className="col-md-12"
                          style={{
                            background: "#F3E1CF",
                            // background: "#99d4f7",
                            minHeight: "100px",
                            maxHeight: "500px",
                            borderRadius: "5px",
                            padding: "15px",
                            marginTop: "5px",
                            position: "relative",
                            // scroll: "hidden",
                            // overflowY: "scroll",
                          }}
                        >
                          <div
                            className="row"
                            style={{
                              justifyContent: "flex-start",
                              marginTop: "15px",
                            }}
                          >
                            <div className="form-group col-sm-2">
                              <label>Reimbursement Name</label>
                              <Select
                                // isDisabled={tripReimbursement.length - 1 !== i}
                                placeholder={<div>Type to search</div>}
                                options={reimbursementsType}
                                value={v.name}
                                onChange={(event) =>
                                  handleFormChange11(
                                    i,
                                    event,
                                    tripReimbursement,
                                    setTripReimbursement
                                  )
                                }
                              />
                            </div>
                            {v.comp?.map((ele, index) => (
                              <>
                                <div className="form-group col-sm-2">
                                  <label>
                                    {ele.name === "Distance Travelled"
                                      ? "Distance Travelled"
                                      : ele.name === "Other Origin City"
                                      ? v.comp[2].value === 95 ||
                                        v.comp[2]?.value === 143 ||
                                        v.comp[2]?.value === 287 ||
                                        v.comp[2]?.value === 335
                                        ? "Origin City Name"
                                        : null
                                      : ele.name === "Other Destination City"
                                      ? v.comp[4]?.value === 95 ||
                                        v.comp[4]?.value === 143 ||
                                        v.comp[4]?.value === 287 ||
                                        v.comp[4]?.value === 335
                                        ? "Destination City Name"
                                        : null
                                      : ele.name === "Other City"
                                      ? v.comp[3]?.value === 95 ||
                                        v.comp[0]?.value === 95 ||
                                        v.comp[3]?.value === 143 ||
                                        v.comp[0]?.value === 143
                                        ? "Name of City"
                                        : null
                                      : ele.name}
                                    {ele.required === 1 && (
                                      <span className="text-danger">*</span>
                                    )}
                                  </label>
                                  {(ele.type === "date" ||
                                    ele.type === "datetime-local") && (
                                    <input
                                      className="form-control"
                                      min={last3months
                                        .toISOString()
                                        .slice(
                                          0,
                                          last3months
                                            .toISOString()
                                            .lastIndexOf(":")
                                        )}
                                      max={moment().format("YYYY-MM-DDThh:mm")}
                                      type={ele.type}
                                      value={ele.value}
                                      onChange={(event) =>
                                        handleFormChange111(
                                          index,
                                          i,
                                          event.target.value,
                                          tripReimbursement,
                                          setTripReimbursement
                                        )
                                      }
                                    />
                                  )}

                                  {ele.type === "text" &&
                                    ele.name !== "Other Origin City" &&
                                    ele.name !== "Other City" &&
                                    ele.name !== "Other Destination City" && (
                                      <input
                                        className="form-control"
                                        type={ele.type}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                      />
                                    )}
                                  {ele.type === "boolean" && (
                                    <input
                                      style={{ marginTop: "5px" }}
                                      // className="form-control"
                                      type="checkbox"
                                      checked={ele.value === 1 ? true : false}
                                      onChange={(event) => {
                                        handleFormChange321(
                                          index,
                                          i,
                                          ele.value === 0 ? true : false,
                                          tripReimbursement,
                                          setTripReimbursement
                                        );
                                      }}
                                    />
                                  )}
                                  {ele.name == "Other Origin City" &&
                                    (v.comp[2]?.value === 95 ||
                                      v.comp[2]?.value === 143 ||
                                      v.comp[2]?.value === 287 ||
                                      v.comp[2]?.value === 335) && (
                                      <input
                                        className="form-control"
                                        disabled={
                                          v.comp[2].value === 95 ||
                                          v.comp[2]?.value === 143 ||
                                          v.comp[2]?.value === 287 ||
                                          v.comp[2]?.value === 335
                                            ? false
                                            : true
                                        }
                                        type={ele.type}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                      />
                                    )}

                                  {ele.name == "Other City" &&
                                    (v.comp[3]?.value === 95 ||
                                      v.comp[3]?.value === 143) && (
                                      <input
                                        className="form-control"
                                        disabled={
                                          v.comp[3]?.value === 95 ||
                                          v.comp[3]?.value === 143
                                            ? false
                                            : true
                                        }
                                        type={ele.type}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                      />
                                    )}
                                  {ele.name == "Other City" &&
                                    (v.comp[0]?.value === 95 ||
                                      v.comp[0]?.value === 143) && (
                                      <input
                                        className="form-control"
                                        disabled={
                                          v.comp[0]?.value === 95 ||
                                          v.comp[0]?.value === 143
                                            ? false
                                            : true
                                        }
                                        type={ele.type}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                      />
                                    )}

                                  {ele.name === "Other Destination City" &&
                                    (v.comp[4]?.value === 95 ||
                                      v.comp[4]?.value === 143 ||
                                      v.comp[4]?.value === 287 ||
                                      v.comp[4]?.value === 335) && (
                                      <input
                                        className="form-control"
                                        type={ele.type}
                                        value={ele.value}
                                        disabled={
                                          v.comp[4].value === 95 ||
                                          v.comp[4]?.value === 143 ||
                                          v.comp[4]?.value === 287 ||
                                          v.comp[4]?.value === 335
                                            ? false
                                            : true
                                        }
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                      />
                                    )}
                                  {ele.type === "number" &&
                                    ele.name !== "Distance Travelled" &&
                                    ele.name !== "Actual Fare" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Outstation Travel Claim" &&
                                    ele.type === "number" &&
                                    ele.name === "Actual Fare" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        disabled={
                                          v.comp[6]?.value === 7 ||
                                          v.comp[6]?.value === 11 ||
                                          v.comp[6]?.value === 9 ||
                                          v.comp[6]?.value === 67 ||
                                          v.comp[6]?.value === 71 ||
                                          v.comp[6]?.value === 69
                                            ? false
                                            : true
                                        }
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Inter-city Travel" &&
                                    ele.type === "number" &&
                                    ele.name === "Actual Fare" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Local Travel Claim" &&
                                    ele.type === "number" &&
                                    ele.name === "Actual Fare" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        disabled={
                                          v.comp[1]?.value === 19 ||
                                          v.comp[1]?.value === 23 ||
                                          v.comp[1]?.value === 21 ||
                                          v.comp[1]?.value === 79 ||
                                          v.comp[1]?.value === 83 ||
                                          v.comp[1]?.value === 81
                                            ? false
                                            : true
                                        }
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Local Travel" &&
                                    ele.type === "number" &&
                                    ele.name === "Actual Fare" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        disabled={
                                          v.comp[1]?.value === 124 ||
                                          v.comp[1]?.value === 128
                                            ? false
                                            : true
                                        }
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Local Travel Claim" &&
                                    ele.type === "number" &&
                                    ele.name === "Distance Travelled" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        disabled={
                                          v.comp[1]?.value === 19 ||
                                          v.comp[1]?.value === 23 ||
                                          v.comp[1]?.value === 21 ||
                                          v.comp[1]?.value === 79 ||
                                          v.comp[1]?.value === 83 ||
                                          v.comp[1]?.value === 81
                                            ? true
                                            : false
                                        }
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Local Travel" &&
                                    ele.type === "number" &&
                                    ele.name === "Distance Travelled" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        disabled={
                                          v.comp[1]?.value === 124 ||
                                          v.comp[1]?.value === 128
                                            ? true
                                            : false
                                        }
                                        type="number"
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Outstation Travel Claim" &&
                                    ele.type === "number" &&
                                    ele.name === "Distance Travelled" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        disabled={
                                          v.comp[6]?.value === 7 ||
                                          v.comp[6]?.value === 11 ||
                                          v.comp[6]?.value === 9 ||
                                          v.comp[6]?.value === 67 ||
                                          v.comp[6]?.value === 71 ||
                                          v.comp[6]?.value === 69
                                            ? true
                                            : false
                                        }
                                        type={ele.type}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange111(
                                            index,
                                            i,
                                            event.target.value,
                                            tripReimbursement,
                                            setTripReimbursement
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {v.name.label === "Inter-city Travel" &&
                                    ele.type === "number" &&
                                    ele.name === "Distance Travelled" && (
                                      <input
                                        className="form-control"
                                        min="0"
                                        onInput={(e) => {
                                          e.target.value =
                                            Math.abs(e.target.value) >= 0
                                              ? Math.abs(e.target.value)
                                              : null;
                                        }}
                                        type={ele.type}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange(
                                            index,
                                            event,
                                            reimburseData,
                                            setReimburseData
                                          )
                                        }
                                        onWheel={(e) =>
                                          numberInputOnWheelPreventChange(e)
                                        }
                                      />
                                    )}
                                  {ele.type === "select" && (
                                    <div>
                                      <Select
                                        options={ele.option}
                                        value={ele.option}
                                        onChange={(event) => {
                                          handleFormChange222(
                                            index,
                                            i,
                                            event,
                                            tripReimbursement,
                                            setTripReimbursement
                                          );
                                        }}
                                      />
                                    </div>
                                  )}

                                  {ele.type === "option" && (
                                    <div>
                                      <Select
                                        options={ele.option}
                                        value={ele.option
                                          .map((ele) => ({
                                            label: ele.label,
                                            value: ele.value,
                                          }))
                                          .find(
                                            (item) => item.value === ele.value
                                          )}
                                        onChange={(event) => {
                                          handleFormChange222(
                                            index,
                                            i,
                                            event,
                                            tripReimbursement,
                                            setTripReimbursement
                                          );
                                        }}
                                      />
                                    </div>
                                  )}

                                  {ele.type === "" && ele.name === "Date" && (
                                    <div>
                                      <input
                                        className="form-control"
                                        type="date"
                                        min={`${last3months.getFullYear()}-${
                                          last3months.getMonth() + 1
                                        }-${last3months.getDate()}`}
                                        value={ele.value}
                                        onChange={(event) =>
                                          handleFormChange(
                                            index,
                                            event,
                                            reimburseData,
                                            setReimburseData
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </>
                            ))}
                            {
                               sessionStorage.getItem("coid")!=="RAWJ225129" &&
                               <>
                            
                            <div className="form-group col-md-2">
                              <label>
                                Claim Allowed{" "}
                                <span
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {/* {applyReimburse.name.label ===
                                    "Outstation Travel Claim" &&
                                    `(Including daily allowance)`} */}
                                </span>
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                disabled
                                onInput={(e) => {
                                  e.target.value =
                                    Math.abs(e.target.value) >= 0
                                      ? Math.abs(e.target.value)
                                      : null;
                                }}
                                value={v.claim_allowed}
                                onChange={(event) =>
                                  handleFormChange333(
                                    i,
                                    event,
                                    "claim_allowed",
                                    tripReimbursement,
                                    setTripReimbursement
                                  )
                                }
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label>
                                Claim Applied
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                min="0"
                                onInput={(e) => {
                                  e.target.value =
                                    Math.abs(e.target.value) >= 0
                                      ? Math.abs(e.target.value)
                                      : null;
                                }}
                                value={v.claim_applied}
                                onChange={(event) =>
                                  handleFormChange333(
                                    i,
                                    event,
                                    "claim_applied",
                                    tripReimbursement,
                                    setTripReimbursement
                                  )
                                }
                                onWheel={(e) =>
                                  numberInputOnWheelPreventChange(e)
                                }
                              />
                            </div>
                           </>
}
                            <div className="form-group col-md-2">
                              <label>
                                Description
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={v.desc}
                                onChange={(event) =>
                                  handleFormChange333(
                                    i,
                                    event,
                                    "desc",
                                    tripReimbursement,
                                    setTripReimbursement
                                  )
                                }
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label>
                                {`Bill (Optional)`}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <input
                                type="file"
                                onChange={(event) =>
                                  handleFormChange334(
                                    i,
                                    event,
                                    "bill",
                                    tripReimbursement,
                                    setTripReimbursement
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                            }}
                          >
                            <button
                              style={{
                                background: "#FE6464",
                                color: "#FFFFFF",
                                border: "none",
                                borderRadius: "50%",
                                padding: "1px 8px",
                              }}
                              onClick={(e) => RemoveField(i)}
                            >
                              x
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                    <div>
                      <button
                        style={{
                          background: "#3298DB",
                          color: "white",
                          border: "none",
                          margin: "10px",
                          borderRadius: "5px",
                          padding: "4px 10px",
                        }}
                        onClick={(e) => AddFields(e)}
                      >
                        Add More
                      </button>
                    </div>
                    {tripReimbursement.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          className=" btn"
                          data-bs-dismiss={
                            tripReimbursement.filter(
                              (ele) => ele.name.label === ""
                            ).length > 0 ||
                            tripReimbursement.filter(
                              (ele) => ele.claim_applied === 0
                            ).length > 0 ||
                            tripReimbursement.filter(
                              (ele) => ele.claim_applied > ele.claim_allowed
                            ).length > 0
                              ? ""
                              : "modal"
                          }
                          style={{ background: "#3298DB", color: "#FFFFFF" }}
                          onClick={(e) => handleApplyTripReimburse(e)}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Reimbursement */}

        <div
          id="edit_reim"
          className="modal custom-modal fade"
          role="dialog"
          //data-bs-backdrop="static"
          //data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: "615px" }}>
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Edit Reimbursement</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-12">
                      <label>
                        Name of Reimbursement
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        // options={[
                        //   ...new Set(reimbursements?.map((item) => item)),
                        // ].map((ele) => ({
                        //   label: ele.name,
                        //   value: ele.id,
                        // }))}
                        // placeholder={<div>Type to search</div>}
                        // options={reimbursementsType}
                        value={reimbursementsType.filter(
                          (ele) => ele.label === editReimburse.name
                        )}
                        isDisabled={true}
                        // onChange={(e) =>
                        //   setEditReimburse({ ...editReimburse, name: e })
                        // }
                      />
                    </div>

                    {reimburseData?.map((ele, index) => (
                      <>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              {ele.name === "Distance Travelled"
                                ? "Distance Travelled (km)"
                                : ele.name === "Other Origin City"
                                ? reimburseData[2].value === 95 ||
                                  reimburseData[2]?.value === 143 ||
                                  reimburseData[2]?.value === 287 ||
                                  reimburseData[2]?.value === 335
                                  ? "Name of Origin City"
                                  : null
                                : ele.name === "Other Destination City"
                                ? reimburseData[4]?.value === 95 ||
                                  reimburseData[4]?.value === 143 ||
                                  reimburseData[4]?.value === 287 ||
                                  reimburseData[4]?.value === 335
                                  ? "Name of Destination City"
                                  : null
                                : ele.name === "Other City"
                                ? reimburseData[3]?.value === 95 ||
                                  reimburseData[0]?.value === 95 ||
                                  reimburseData[3]?.value === 143 ||
                                  reimburseData[0]?.value === 143
                                  ? "Name of City"
                                  : null
                                : ele.name}
                              {ele.required === 1 && (
                                <span className="text-danger">*</span>
                              )}
                            </label>
                            {(ele.type === "date" ||
                              ele.type === "datetime-local") && (
                              <input
                                className="form-control"
                                min={last3months
                                  .toISOString()
                                  .slice(
                                    0,
                                    last3months.toISOString().lastIndexOf(":")
                                  )}
                                max={moment().format("YYYY-MM-DDThh:mm")}
                                type={ele.type}
                                value={ele.value}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    reimburseData,
                                    setReimburseData
                                  )
                                }
                              />
                            )}

                            {ele.type === "text" &&
                              ele.name !== "Other Origin City" &&
                              ele.name !== "Other City" &&
                              ele.name !== "Other Destination City" && (
                                <input
                                  className="form-control"
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}
                            {ele.type === "boolean" && (
                              <input
                                style={{ marginTop: "5px" }}
                                // className="form-control"
                                type="checkbox"
                                // value={ele.value}
                                checked={ele.value === 1 ? true : false}
                                onChange={(event) => {
                                  handleFormChange12(
                                    index,
                                    ele.value === 0 ? true : false,
                                    reimburseData,
                                    setReimburseData
                                  );
                                }}
                              />
                            )}
                            {ele.name == "Other Origin City" &&
                              (reimburseData[2]?.value === 95 ||
                                reimburseData[2]?.value === 143 ||
                                reimburseData[2]?.value === 287 ||
                                reimburseData[2]?.value === 335) && (
                                <input
                                  className="form-control"
                                  disabled={
                                    reimburseData[2].value === 95 ||
                                    reimburseData[2]?.value === 143 ||
                                    reimburseData[2]?.value === 287 ||
                                    reimburseData[2]?.value === 335
                                      ? false
                                      : true
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}

                            {ele.name == "Other City" &&
                              (reimburseData[3]?.value === 95 ||
                                reimburseData[3]?.value === 143) && (
                                <input
                                  className="form-control"
                                  disabled={
                                    reimburseData[3]?.value === 95 ||
                                    reimburseData[3]?.value === 143
                                      ? false
                                      : true
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}
                            {ele.name == "Other City" &&
                              (reimburseData[0]?.value === 95 ||
                                reimburseData[0]?.value === 143) && (
                                <input
                                  className="form-control"
                                  disabled={
                                    reimburseData[0]?.value === 95 ||
                                    reimburseData[0]?.value === 143
                                      ? false
                                      : true
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}

                            {ele.name === "Other Destination City" &&
                              (reimburseData[4]?.value === 95 ||
                                reimburseData[4]?.value === 143 ||
                                reimburseData[4]?.value === 287 ||
                                reimburseData[4]?.value === 335) && (
                                <input
                                  className="form-control"
                                  type={ele.type}
                                  value={ele.value}
                                  disabled={
                                    reimburseData[4].value === 95 ||
                                    reimburseData[4]?.value === 143 ||
                                    reimburseData[4]?.value === 287 ||
                                    reimburseData[4]?.value === 335
                                      ? false
                                      : true
                                  }
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              )}
                            {ele.type === "number" &&
                              ele.name !== "Distance Travelled" &&
                              ele.name !== "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele?.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Outstation Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  disabled={
                                    reimburseData[6]?.value === 7 ||
                                    reimburseData[6]?.value === 11 ||
                                    reimburseData[6]?.value === 9 ||
                                    reimburseData[6]?.value === 67 ||
                                    reimburseData[6]?.value === 71 ||
                                    reimburseData[6]?.value === 69
                                      ? false
                                      : true
                                  }
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele?.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Inter-city Travel" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele?.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Local Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  disabled={
                                    reimburseData[1]?.value === 19 ||
                                    reimburseData[1]?.value === 23 ||
                                    reimburseData[1]?.value === 21 ||
                                    reimburseData[1]?.value === 79 ||
                                    reimburseData[1]?.value === 83 ||
                                    reimburseData[1]?.value === 81
                                      ? false
                                      : true
                                  }
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Local Travel" &&
                              ele.type === "number" &&
                              ele.name === "Actual Fare" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[1]?.value === 124 ||
                                    reimburseData[1]?.value === 128
                                      ? false
                                      : true
                                  }
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Local Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[1]?.value === 19 ||
                                    reimburseData[1]?.value === 23 ||
                                    reimburseData[1]?.value === 21 ||
                                    reimburseData[1]?.value === 79 ||
                                    reimburseData[1]?.value === 83 ||
                                    reimburseData[1]?.value === 81
                                      ? true
                                      : false
                                  }
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Local Travel" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[1]?.value === 124 ||
                                    reimburseData[1]?.value === 128
                                      ? true
                                      : false
                                  }
                                  type="number"
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Outstation Travel Claim" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  disabled={
                                    reimburseData[6]?.value === 7 ||
                                    reimburseData[6]?.value === 11 ||
                                    reimburseData[6]?.value === 9 ||
                                    reimburseData[6]?.value === 67 ||
                                    reimburseData[6]?.value === 71 ||
                                    reimburseData[6]?.value === 69
                                      ? true
                                      : false
                                  }
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {editReimburse.name === "Inter-city Travel" &&
                              ele.type === "number" &&
                              ele.name === "Distance Travelled" && (
                                <input
                                  className="form-control"
                                  min="0"
                                  onInput={(e) => {
                                    e.target.value =
                                      Math.abs(e.target.value) >= 0
                                        ? Math.abs(e.target.value)
                                        : null;
                                  }}
                                  type={ele.type}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                  onWheel={(e) =>
                                    numberInputOnWheelPreventChange(e)
                                  }
                                />
                              )}
                            {ele.type === "select" && (
                              <div>
                                <Select
                                  options={ele.option}
                                  value={ele.option}
                                  onChange={(event) => {
                                    handleFormChange1(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    );
                                  }}
                                />
                              </div>
                            )}

                            {ele.type === "option" && (
                              <div>
                                <Select
                                  options={ele.option}
                                  defaultValue={ele.option
                                    .map((ele) => ({
                                      label: ele.label,
                                      value: ele.value,
                                    }))
                                    .find((item) => item.value === ele.value)}
                                  value={ele.option
                                    .map((ele) => ({
                                      label: ele.label,
                                      value: ele.value,
                                    }))
                                    .find((item) => item.value === ele.value)}
                                  onChange={(event) => {
                                    handleFormChange1(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    );
                                  }}
                                />
                              </div>
                            )}

                            {ele.type === "" && ele.name === "Date" && (
                              <div>
                                <input
                                  className="form-control"
                                  type="date"
                                  min={`${last3months.getFullYear()}-${
                                    last3months.getMonth() + 1
                                  }-${last3months.getDate()}`}
                                  value={ele.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      reimburseData,
                                      setReimburseData
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="form-group col-md-12">
                      <label>
                        Reimbursement Description
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={editReimburse.additional_note}
                        onChange={(e) =>
                          setEditReimburse({
                            ...editReimburse,
                            additional_note: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-group col-md-7">
                      <label>
                        Claim Allowed{" "}
                        <span
                          style={{
                            fontSize: "11px",
                            fontWeight: "500",
                          }}
                        >
                          {editReimburse.name === "Outstation Travel Claim" &&
                            `(Including daily allowance)`}
                        </span>
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        disabled
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={editReimburse.claim_allowed}
                        onChange={(e) =>
                          setEditReimburse({
                            ...editReimburse,
                            claim_allowed: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-5">
                      <label>
                        Claim Applied
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={editReimburse.claim_applied}
                        onChange={(e) =>
                          setEditReimburse({
                            ...editReimburse,
                            claim_applied: e.target.value,
                          })
                        }
                        onWheel={(e) => numberInputOnWheelPreventChange(e)}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label>
                        {`Reimbursement Bill (Optional)`}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="file"
                        onChange={(e) => setReimBill(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss={
                        !editReimburse.claim_allowed ||
                        !editReimburse.claim_applied ||
                        Number(editReimburse.claim_applied) >
                          Number(editReimburse.claim_allowed)
                          ? ""
                          : "modal"
                      }
                      style={{
                        background: "#329fd1",
                        width: "fit-content",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                        color: "white",
                      }}
                      onClick={(event) => handleEditReimburse(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reimbursement;
