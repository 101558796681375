import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { ConfigProvider } from "antd";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import empty from "../../../assets/img/profiles/browser.png";
import { FiUpload, FiDownload } from "react-icons/fi";
import { Modal } from "react-bootstrap";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No data to show</p>
  </div>
);

const RRDeduction = () => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [data, setData] = useState([]);
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const hiddenFileInput = useRef(null);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/UploadRrwlDeduction/`,

        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            coregid: sessionStorage.getItem("coid"),
            toggle: "a",
          },
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        }
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [monthValue]);

  const handleUpload = (event) => {
    // const fileUploaded = event.target.files[0];
    // setBioExcel(fileUploaded);
    // uploadAttendance();
    setLoadingModal(true);
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("coregid", sessionStorage.getItem("coid"));
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/UploadRrwlDeduction/`,
        formData
      )
      .then((res) => {
        setLoadingModal(false);
        Swal.fire({
          icon: "success",
          text: "Deductions uploaded successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "empname",
      fixed: "left",
      sorter: (a, b) => a.empname.localeCompare(b.empname),
    },
    {
      title: "Employee Code",
      dataIndex: "empcode",
      fixed: "left",
      sorter: (a, b) => a.empcode.localeCompare(b.empcode),
    },
    {
      title: "Zing Code",
      dataIndex: "empcode",
      fixed: "left",
      sorter: (a, b) => a.empcode.localeCompare(b.empcode),
    },
    {
      title: "Office Name",
      dataIndex: "offname",
      fixed: "left",
      sorter: (a, b) => a.offname.localeCompare(b.offname),
    },
    {
      title: "Electric",
      dataIndex: "electric",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Mess",
      dataIndex: "mess",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Maintainance Charges",
      dataIndex: "maintainance_charges",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Loan",
      dataIndex: "loan",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Advance",
      dataIndex: "advance",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "TDS",
      dataIndex: "tds",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Other",
      dataIndex: "other",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "PT",
      dataIndex: "pt",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Rent",
      dataIndex: "rent",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "LIC",
      dataIndex: "lic",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "NPS",
      dataIndex: "nps",
      align: "center",
      render: (text, record) => <>{text ? text : "--"}</>,
    },
    {
      title: "Total Deduction ",
      dataIndex: "total_deduction",
      align: "center",
   
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Deduction</h3>
              </div>
              <div className="col-auto float-end">
                <button
                  className=""
                  classname="edit-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "19px 23px",
                    height: "30px",
                    fontSize: "15px",
                    background: " #2b8f6f",
                    border: "none",
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <Box>
                    <Tooltip title="Add Bulk File">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        color="white"
                        // sx={{ ml: -3 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        // className={classes.button}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          <FiUpload />
                          &nbsp;&nbsp; Deduction
                        </p>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </button>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <MenuItem>
                    <a
                      // onClick={() => handleExcelDownload()}
                      href={`${process.env.REACT_APP_BACKEND_URL}/UploadRrwlDeduction/?toggle=t`}
                      download
                      target="_blank"
                    >
                      Download Template
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a onClick={(e) => handleClick1(e)}>Upload File</a>
                  </MenuItem>
                </Menu>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleUpload}
                  style={{ display: "none" }}
                />
              </div>
              {/* <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    onClick={(e) => handleClick(e)}
                  >
                    <FiUpload /> &nbsp;&nbsp; Deduction
                  </button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleUpload}
                    style={{ display: "none" }}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    // marginBottom: "-48px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <input
                    style={{
                      width: "150px",
                      borderRadius: "5px",
                      height: "30px",
                      padding: "10px",
                    }}
                    type="month"
                    value={monthValue}
                    onChange={(e) => setMonthValue(e.target.value)}
                  />
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <ConfigProvider
                      renderEmpty={
                        // customize ?
                        customizeRenderEmpty
                        // : undefined
                      }
                    >
                      <Table
                        className="table-striped"
                        exportableProps={{ showColumnPicker: true }}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        loading={isLoading}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="modal custom-modal fade"
            role="dialog"
            id="deduction_upload"
            // tabindex="-1"
            // aria-labelledby="exampleModalLabel"
            // aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    style={{ marginTop: "10px", fontWeight: "600" }}
                  >
                    Monthly Deduction upload
                  </h5>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <img src={closeIcon} />
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <label for="message-text" class="col-form-label">
                        <b>Select Month :</b>
                      </label>
                      <input
                        class="form-control"
                        id="message-text"
                        min="2024-05"
                        type="month"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      />
                      {error !== "" && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {error}
                        </p>
                      )}
                    </div>
                    <div class="form-group">
                      <label for="message-file" class="col-form-label">
                        <b>Select File :</b>
                      </label>
                      <input
                        class="form-control"
                        id="message-file"
                        type="file"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    // onClick={(e) => handleAdjustLeave(e)}
                    data-bs-dismiss="modal"
                    className="submit-btn2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <Modal
            show={loadingModal}
            onHide={() => {
              setLoadingModal(false);
            }}
            centered
            size="md"
            closeButton
            contentClassName="modal-height"
          >
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div display="flex" flexDirection="column" gap={3}>
                  <h3 style={{ fontSize: "16px", fontWeight: 800 }}>
                    Uploading.....
                  </h3>
                  <h6 style={{ fontSize: "14px", fontWeight: 400 }}>
                    Please wait while we are uploading the report
                  </h6>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default RRDeduction;
