import React, { useEffect, useState } from "react";
// import { Table } from "antd";
import { Table } from "ant-table-extensions";
import Select from "react-select";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../../components/paginationfunction";

import "../../../../antdstyle.css";
import eye from "../../../../assets/img/profiles/Group813.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterOff from "../../../../assets/img/profiles/filter-off-fill1.svg";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Back from "../../../../assets/img/profiles/arrow-left.svg";
import Button from "@mui/material/Button";
import { CiExport } from "react-icons/ci";
import { border } from "@mui/system";
import search from "../../../../assets/img/profiles/search-line.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const Addpayroll = (props) => {
  const {
    show,
    setShow,
    empPay,
    setEmpPay,
    month,
    setMonth,
    pay,
    selectedRowKeys,
    setSelectedRowKeys,
    handleHoldPayroll,
    dyCol,
    empi,
    setEmpi,
    showp,
    setShowp,
    handlePayslip,
    show1,
    loading,
  } = props;
  const [hide, setHide] = useState(true);
  const [name1, setName1] = useState("");
  const [type1, setType1] = useState("");
  const [location, setLocation] = useState("");
  const [department1, setDepartment1] = useState("");
  const [searchData, setSearchData] = useState([]);

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  useEffect(() => {
    if (name1 === "" && type1 === "" && department1 === "" && location=== "") {
      setSearchData(empPay);
    }
  });

  const handleSearch1 = () => {
    const newData = empPay
      .filter((ele) => {
        return name1.toLocaleLowerCase() === ""
          ? ele
          : ele.employee_name
              .toLocaleLowerCase()
              .includes(name1.toLocaleLowerCase()) ||
            name1.toLocaleLowerCase() === ""
          ? ele
          : String(ele.emp_code)
              .toLocaleLowerCase()
              .includes(name1.toLocaleLowerCase());
      })
      .filter(
        (y) => y.department == (department1 == "" ? y.department : department1)
      )
      .filter(
        (y) =>
          y.office_name == (location == "" ? y.office_name : location)
      )
      .filter(
        (z) => z.employement_type == (type1 == "" ? z.employement_type : type1)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch1();
  }, [name1, department1, type1, location]);

  console.log(location)
  console.log(searchData)

  const columns1 = dyCol.map((ele) => {
    if (ele.key === "employee_name") {
      return {
        title: ele.name,
        width: 200,
        dataIndex: ele.key,
        key: ele.key,
        align: "left",
        fixed: "left",
      };
    } else if (ele.key === "employee_id") {
      return {
        title: ele.name,
        width: 100,
        dataIndex: ele.key,
        key: ele.key,
        align: "left",
        fixed: "left",
      };
    } else {
      return {
        title: ele.name,
        width: 180,
        dataIndex: ele.key,
        key: ele.key,
        align: "right",
      };
    }
  });

  const columns = [
    {
      title: "Employee ID",
      //   width: 100,
      dataIndex: "emp_id",
      key: "emp_id",
      fixed: "left",
    },
    {
      title: "Employee Name",
      width: 150,
      dataIndex: "emp_name",
      key: "emp_name",
      fixed: "left",
      sorter: (a, b) => a.emp_name?.localeCompare(b?.emp_name),
    },
    { title: "Department", dataIndex: "emp_dept", key: "emp_dept" },
    { title: "Pay Days", dataIndex: "day_no", key: "day_no" },
    {
      title: "Basic Pay",
      dataIndex: "basic",
      key: "basic",
      render: (text, record) => <>{Math.round(JSON.parse(text))}</>,
    },
    { title: "Deductions", dataIndex: "deduction", key: "deduction" },
    { title: "Taxes", dataIndex: "taxes", key: "taxes" },
    {
      title: "Reimbursements",
      dataIndex: "reimbursement",
      key: "reimbursement",
      width: 150,
    },
    {
      title: "Variables",
      dataIndex: "variable",
      key: "variable",
      render: (text, record) => <>{text ? text : 0}</>,
    },
    {
      title: "Net Pay",
      dataIndex: "ctc",
      key: "ctc",
      fixed: "right",
      width: 100,
      render: (text, record) => <>{Math.round(JSON.parse(text))}</>,
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 100,
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action ">
    //       <a
    //         href="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <Button variant="contained" >
    //             <MoreHorizIcon />
    //         </Button>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right dropdown-insets">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           onClick={()=> setShow(2)}
    //         >
    //         <img
    //         src={eye}
    //         style={{
    //           height: "10px",
    //           width: "15px",
    //         }}/>
    //         <b> View Salary Breakup</b>
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           onClick={()=>setShow(3)}
    //         >
    //           <i className="fa fa-pencil m-r-5" /><b> Revise Salary</b>
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_employee"
    //         >
    //           <i className="fa fa-trash-o m-r-5" /><b> Skip Payroll</b>
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
    ...(!hide
      ? [
          {
            title: "Action",
            key: "action",
            dataIndex: "action",
            hidden: true,
            fixed: "right",
          },
        ]
      : []),
  ];

  return (
    <div className="row" style={{ display: "flex", flexDirection: "row" }}>
      <div className="col-sm-6 col-md-5">
        <div className="form-group form-focus select-focus">
          {/* <Select
            inputId="single-select-example"
            className="single-select"
            classNamePrefix="react-select"
            placeholder="Employee Name"
            options={[...new Set(empPay?.map((item) => item.emp_name))].map(
              (ele) => ({
                label: ele,
                value: ele,
              })
            )}
            value={
              name1 !== ""
                ? {
                    label: name1,
                    value: name1,
                  }
                : null
            }
            onChange={(e) => {
              setName1(e.value);
            }}
           
            style={{
              zindex: "5",
              height: "50px",
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "3px",
            }}
          
          /> */}
          <input
            className="form-group col-md-10"
            style={{
              height: "38px",
              paddingLeft: "20px",
              marginTop: "1px",
              borderRadius: "5px",
            }}
            placeholder="Search by name and employee id"
            value={name1}
            onChange={(e) => setName1(e.target.value)}
          />
          <button
            className="col-md-2"
            style={{
              background: "#393f5c",
              padding: "7px",
              border: "none",
              borderRadius: "2px",
            }}
          >
            <img src={search} />
          </button>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="form-group form-focus select-focus">
          <Select
            inputId="single-select-example"
            className="single-select"
            classNamePrefix="react-select"
            placeholder="Employee Type"
            isClearable
            options={[
              ...new Set(empPay?.map((ele) => ele.employement_type)),
            ].map((ele) => {
              return {
                label: ele,
                value: ele,
              };
            })}
            value={
              type1 !== ""
                ? {
                    label: type1,
                    value: type1,
                  }
                : null
            }
            onChange={(e) => {
              setType1(e ? e.value : "");
            }}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            style={{
              zindex: "5",
              height: "50px",
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "3px",
            }}
          />
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="form-group form-focus select-focus">
          <Select
            inputId="single-select-example"
            className="single-select"
            classNamePrefix="react-select"
            placeholder="Working Location"
            isClearable
            options={[
              ...new Set(empPay?.map((ele) => ele.office_name)),
            ].map((ele) => {
              return {
                label: ele,
                value: ele,
              };
            })}
            value={
              location !== ""
                ? {
                    label: location,
                    value: location,
                  }
                : null
            }
            onChange={(e) => {
              setLocation(e ? e.value : "");
            }}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            style={{
              zindex: "5",
              height: "50px",
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "3px",
            }}
          />
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="form-group form-focus select-focus">
          <Select
            inputId="single-select-example"
            className="single-select"
            classNamePrefix="react-select"
            placeholder="Department"
            isClearable
            options={[...new Set(empPay?.map((ele) => ele.department))].map(
              (ele) => {
                return {
                  label: ele,
                  value: ele,
                };
              }
            )}
            value={
              department1 !== ""
                ? {
                    label: department1,
                    value: department1,
                  }
                : null
            }
            onChange={(e) => {
              setDepartment1(e ? e.value : "");
            }}
            // options={[
            //   ...new Set(empPayrollDetails?.map((item) => item.emp_name)),
            // ].map((ele) => ({
            //   label: ele,
            //   value: ele,
            // }))}
            // value={
            //   name1 !== ""
            //     ? {
            //         label: name1,
            //         value: name1,
            //       }
            //     : null
            // }
            // onChange={(e) => setName1(e.value)}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            style={{
              zindex: "5",
              height: "50px",
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "3px",
            }}
          />
        </div>
      </div>

      <div className="col-sm-6 col-md-1">
        <img
          tabindex="0"
          data-bs-toggle="tooltip"
          title="clear all filter"
          style={{
            height: "30px",
            // backgroundColor: "#3298DB",
            padding: "1.5px 5px 2px 5px",
            borderRadius: "5px",
          }}
          src={FilterOff}
          onClick={() => (
            setType1(""), setDepartment1(""), setName1(""), setLocation("")
          )}
        />

        {/* <button
          style={{
            height: "38px",
            backgroundColor: "#0EAA00",
            padding: "1px 25px 2px 25px",
            borderRadius: "5px",
            marginLeft: "10px",
            border: "none",
            color: "white",
          }}
        >
          <b>RUN PAYROLL</b>
        </button> */}
        {/* <button
          style={{
            height: "38px",
            backgroundColor: "#3298DB",
            padding: "1px 15px 2px 15px",
            borderRadius: "5px",
            border: "none",
            color: "white",
            marginLeft: "20px",
          }}
        >
          <CiExport style={{ marginRight: "7px", fontSize: "22" }} />
          EXPORT
        </button> */}
      </div>

      <div className="col-md-12">
        <div className="card mb-0 ">
          <div className="card-body">
            {selectedRowKeys.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <div className="regularize-part">
                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      padding: "6px 30px",
                      background: "#2B8F6F",
                      color: "#fff",
                      borderRadius: "6px",
                    }}
                    onClick={(e) => handleHoldPayroll(e)}
                  >
                    Hold Payroll
                  </button>
                </div>
              </div>
            )}

            <div className="table-responsive">
              <Table
                // className="table-striped"
                // searchable
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                rowSelection={rowSelection}
                exportable
                exportableProps={{ showColumnPicker: true }}
                columns={[
                  ...columns1,
                  {
                    title: "Action",
                    key: "action",
                    dataIndex: "employee_id",
                    hidden: true,
                    // fixed: "right",
                    render: (text, record) =>
                      show1?.includes(text) && (
                        <button
                          className="p-btn"
                          onClick={() =>
                            // setEmpi(text);
                            {
                              handlePayslip(text);
                              setShowp(true);
                            }
                          }
                        >
                          View Payslip
                        </button>
                      ),
                  },
                ]}
                dataSource={searchData}
                pagination={{
                  total: searchData.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                scroll={{ x: 1300 }}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addpayroll;
